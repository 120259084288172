// src/components/mapcomponents/SettingsPopup.js

import React from 'react';
import PropTypes from 'prop-types';
import BasemapDropdown from './BasemapDropdown';
import styles from '../../styles/SettingsPopup.module.css'; // Ensure this CSS module exists
import { FaTimes } from 'react-icons/fa'; // Import a close icon

const SettingsPopup = ({
  basemaps,
  selectedBasemap,
  onBasemapChange,
  onClose, // Add onClose prop to handle popup closure
}) => {
  return (
    <div className={styles.popupContainer}>
      <div className={styles.popupHeader}>
        <h3>Map Settings</h3>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close Map Settings"
        >
          <FaTimes />
        </button>
      </div>
      <BasemapDropdown
        basemaps={basemaps}
        selectedBasemap={selectedBasemap}
        onBasemapChange={onBasemapChange}
      />
    </div>
  );
};

SettingsPopup.propTypes = {
  basemaps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      attribution: PropTypes.string.isRequired,
      subdomains: PropTypes.arrayOf(PropTypes.string),
      grayscale: PropTypes.bool,
    })
  ).isRequired,
  selectedBasemap: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    attribution: PropTypes.string.isRequired,
    subdomains: PropTypes.arrayOf(PropTypes.string),
    grayscale: PropTypes.bool,
  }).isRequired,
  onBasemapChange: PropTypes.func.isRequired,
  basemapOpacity: PropTypes.number.isRequired,
  onOpacityChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SettingsPopup;

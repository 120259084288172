// src/components/Dashboard.js
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import api from '../../../api';
import styles from '../styles/Dashboard.module.css'; // Import as styles

const Dashboard = () => {
    const { logout, auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const res = await api.get(`/services`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });
                setServices(res.data);
                setLoading(false);
            } catch(err) {
                console.error(err);
                setError('Failed to load services.');
                setLoading(false);
            }
        };
        fetchServices();
    }, [auth.token]);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    if (loading) {
        return <div className={styles.container}><p className={styles.loading}>Loading dashboard...</p></div>;
    }

    if (error) {
        return <div className={styles.container}><p className={styles.error}>{error}</p></div>;
    }

    return (
        <div className={styles.container}>
            {/* Header Section */}
            <div className={styles.header}>
                <h2 className={styles.title}>Dashboard</h2>
                <button onClick={handleLogout} className={styles.logoutButton}>Login</button>
            </div>

            {/* Admin Link Section */}
            {auth.user && auth.user.role === 'admin' && (
                <div className={styles.adminLinkContainer}>
                    <Link to="/admin" className={styles.adminLink}>
                        Go to Admin Dashboard
                    </Link>
                </div>
            )}

            {/* Services Selection */}
            <div className={styles.services}>
                {services.map(service => (
                    <button 
                        key={service._id} 
                        onClick={() => navigate(`/services/${service._id}`)}
                        className={styles.serviceButton}
                    >
                        {service.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Dashboard;

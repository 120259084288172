// src/components/TunnelProjectDetail/TunnelProjectDetail.js

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../auth/context/AuthContext';
import styles from '../../common/styles/ProjectDetail.module.css';
import TunnelImageViewer from './TunnelImageViewer';
import useTunnelImages from '../hooks/useTunnelImages';
import useTunnelCycle from '../hooks/useTunnelCycle';

const TunnelProjectDetail = ({ project }) => {
  const projectId = project._id;
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  const [directionFilter, setDirectionFilter] = useState('All');
  const [cameraPositionFilter, setCameraPositionFilter] = useState('All');
  const [imageAspectRatio, setImageAspectRatio] = useState(null);

  const { loading, error, images } = useTunnelImages(
    projectId,
    auth.token,
    directionFilter,
    cameraPositionFilter
  );

  const {
    currentIndex,
    setCurrentIndex,
    displayedImage,
  } = useTunnelCycle(images);

  const handleDirectionChange = (e) => {
    setDirectionFilter(e.target.value);
  };

  const handleCameraPositionChange = (e) => {
    setCameraPositionFilter(e.target.value);
  };

  const handleFilterReset = () => {
    setDirectionFilter('All');
    setCameraPositionFilter('All');
  };

  const handleSliderChange = (e) => {
    const newIndex = Number(e.target.value);
    setCurrentIndex(newIndex);
  };

  const handleImageLoad = (aspectRatio) => {
    setImageAspectRatio(aspectRatio);
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
        <p className={styles.loading}>Loading images...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
        <p className={styles.error}>{error}</p>
      </div>
    );
  }

  if (!images.length) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
        <p className={styles.error}>No images available.</p>
      </div>
    );
  }

  // Added check for displayedImage to prevent accessing properties of null
  if (!displayedImage) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
        <p className={styles.loading}>Loading image...</p>
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      style={imageAspectRatio ? { '--aspect-ratio': imageAspectRatio } : {}}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>{project.name}</h2>
        <button onClick={() => navigate(-1)} className={styles.backButton}>
          Back
        </button>
      </div>

      {/* Filters */}
      <div className={styles.filterContainer}>
        {/* Direction Filter */}
        <label htmlFor="directionFilter" className={styles.filterLabel}>
          Filter by Direction:
        </label>
        <select
          id="directionFilter"
          value={directionFilter}
          onChange={handleDirectionChange}
          className={styles.filterSelect}
        >
          <option value="All">All</option>
          <option value="Horizontal">Horizontal</option>
          <option value="Vertical">Vertical</option>
        </select>

        {/* Camera Position Filter */}
        <label htmlFor="cameraPositionFilter" className={styles.filterLabel}>
          Filter by Camera Position:
        </label>
        <select
          id="cameraPositionFilter"
          value={cameraPositionFilter}
          onChange={handleCameraPositionChange}
          className={styles.filterSelect}
        >
          <option value="All">All</option>
          <option value="right">Right</option>
          <option value="left">Left</option>
        </select>

        {/* Reset Filters Button */}
        <button onClick={handleFilterReset} className={styles.resetButton}>
          Reset Filters
        </button>
      </div>

      <div className={styles.imageViewer}>
        <TunnelImageViewer
          displayedImage={displayedImage}
          onImageLoad={handleImageLoad}
        />

        <div className={styles.sliderContainer}>
          <input
            type="range"
            min="0"
            max={images.length - 1}
            value={currentIndex}
            onChange={handleSliderChange}
            className={styles.slider}
          />
          <div className={styles.tunnelMetadata}>
            <strong>Prog.:</strong> {displayedImage.progressiva}
            <br />
            <strong>Dir.:</strong> {displayedImage.direction}
            <br />
            <strong>Side:</strong>{' '}
            {displayedImage.cameraPosition.charAt(0).toUpperCase() +
              displayedImage.cameraPosition.slice(1)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TunnelProjectDetail;

// src/components/mapcomponents/BasemapDropdown.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/BasemapDropdown.module.css'; // Updated CSS module path

const BasemapDropdown = ({ basemaps, selectedBasemap, onBasemapChange }) => {
  const handleChange = (e) => {
    const selected = basemaps.find((bm) => bm.name === e.target.value);
    if (selected) {
      onBasemapChange(selected);
    }
  };

  return (
    <div className={styles.basemapDropdown}>
      <label htmlFor="basemap-select" className={styles.label}>
        Basemap:
      </label>
      <select
        id="basemap-select"
        value={selectedBasemap.name}
        onChange={handleChange}
        className={styles.select}
        aria-label="Select Basemap"
      >
        {basemaps.map((basemap) => (
          <option key={basemap.name} value={basemap.name}>
            {basemap.name}
          </option>
        ))}
      </select>
    </div>
  );
};

BasemapDropdown.propTypes = {
  basemaps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      attribution: PropTypes.string.isRequired,
      subdomains: PropTypes.arrayOf(PropTypes.string),
      grayscale: PropTypes.bool,
    })
  ).isRequired,
  selectedBasemap: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    attribution: PropTypes.string.isRequired,
    subdomains: PropTypes.arrayOf(PropTypes.string),
    grayscale: PropTypes.bool,
  }).isRequired,
  onBasemapChange: PropTypes.func.isRequired,
};

export default BasemapDropdown;

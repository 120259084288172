// src/components/UI/BackButton.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/SgamPage.module.css'; // Adjust the path as needed

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page
  };

  return (
    <button className={styles.backButton} onClick={handleBack}>
      Back
    </button>
  );
};

export default BackButton;


  
// src/components/LandslideProjectDetail/TimeseriesChart.js

import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
} from 'recharts';
import PropTypes from 'prop-types';

const generateYTicks = (domain, step = 5) => {
    const [min, max] = domain;
    const ticks = [];
    const start = Math.ceil(min / step) * step;
    const end = Math.floor(max / step) * step;

    for (let i = start; i <= end; i += step) {
        ticks.push(i);
    }

    return ticks;
};

const TimeseriesChart = ({ data, type, color = '#8884d8', yDomain = ['auto', 'auto'] }) => {
    const yTicks = generateYTicks(yDomain);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 5 }}
            >
                {/* Cartesian Grid with default styling */}
                <CartesianGrid stroke="#ccc" />

                {/* X-Axis with existing configuration */}
                <XAxis 
                    dataKey="date" 
                    tickFormatter={(tick) => new Date(tick).toLocaleDateString()} 
                    minTickGap={20}
                    padding={{ left: 0, right: 0 }}
                />

                {/* Y-Axis with custom ticks for grid spacing */}
 
                <YAxis 
                    domain={yDomain}
                    ticks={yTicks}
                    allowDataOverflow={false}
                    label={{
                        value: 'Displacement (mm)',
                        angle: -90,
                        position: 'auto',
                        style: {fontSize: '12px' },
                    }}
                />

                {/* Tooltip with existing configuration */}
                <Tooltip 
                    labelFormatter={(label) => `Date: ${new Date(label).toLocaleDateString()}`} 
                />

                {/* Line representing the timeseries data */}
                <Line 
                    type="monotone" 
                    dataKey="displacement" 
                    stroke={type === 'single' ? '#8884d8' : color || '#8884d8'} 
                    name={type === 'single' ? 'Displacement' : 'Average Displacement'} 
                    strokeWidth={1}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

// Define PropTypes for better type checking
TimeseriesChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(Date),
                PropTypes.number,
            ]).isRequired,
            displacement: PropTypes.number.isRequired,
        })
    ).isRequired,
    type: PropTypes.oneOf(['single', 'multi']).isRequired,
    color: PropTypes.string,
    yDomain: PropTypes.arrayOf(PropTypes.number),
};

export default TimeseriesChart;

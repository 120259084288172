import { useMemo, useEffect } from 'react';
import { getColor } from '../utils/mapUtils';

export const useGeoJSONLayers = ({
  geojsonDataArray,
  selectedFeature,
  selectedStructures,
  hoveredFeatureId,
  basemapOpacity,
  mapRef
}) => {
  // Generate unique layer and source IDs
  const geojsonSources = useMemo(() => {
    return geojsonDataArray.map((item, index) => {
      const geometryType =
        item.geojson?.features?.[0]?.geometry?.type || null;

      const uniqueValues = [
        ...new Set(
          item.geojson.features.map(
            (feature) => feature.properties[selectedFeature]
          )
        ),
      ];

      const colorExpression = [
        'match',
        ['get', selectedFeature],
        ...uniqueValues.flatMap((value) => [String(value), getColor(value)]),
        '#CCCCCC', // default color
      ];

      // Filter based on selectedStructures
      const filter =
        selectedStructures.length > 0
          ? ['in', ['get', 'structure'], ['literal', selectedStructures]]
          : ['in', ['get', 'structure'], ['literal', []]];

      return {
        id: `geojson-source-${index}`,
        type: 'geojson',
        data: {
          ...item.geojson,
          features: item.geojson.features.map((feature, fIndex) => ({
            ...feature,
            id: feature.id || `${index}-${fIndex}`,
          })),
        },
        layers: [
          // Outline Layer
          {
            id: `geojson-outline-layer-${index}`,
            type: 'line',
            source: `geojson-source-${index}`,
            filter: filter,
            paint: {
              'line-color': '#000000', // Black color for outline
              'line-width': 6.5,          // Thin line
              'line-opacity': basemapOpacity,
            },
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
              visibility: 'visible',
            },
          },
          // Main Line Layer
          {
            id: `geojson-line-layer-${index}`,
            type: 'line',
            source: `geojson-source-${index}`,
            filter: filter,
            paint: {
              'line-color': colorExpression,
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                4,
                22,
                6,
              ],
              'line-opacity': basemapOpacity,
            },
            layout: {
              visibility: 'visible',
              'line-cap': 'round',
              'line-join': 'round',
            },
          },
          // Highlight Layer
          {
            id: `geojson-line-layer-highlight-${index}`,
            type: 'line',
            source: `geojson-source-${index}`,
            paint: {
              'line-color': '#FF0000',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                6,
                22,
                8,
              ],
              'line-opacity': 1,
            },
            filter:
              hoveredFeatureId !== null
                ? ['==', ['id'], hoveredFeatureId]
                : ['==', ['id'], ''],
          },
        ],
        geometryType: geometryType,
      };
    });
  }, [geojsonDataArray, selectedFeature, selectedStructures, hoveredFeatureId, basemapOpacity]);

  // Get all layer IDs including outline layers
  const layerIds = useMemo(() => {
    return geojsonSources.flatMap((_, index) => [
      `geojson-outline-layer-${index}`,         // Outline layer
      `geojson-line-layer-${index}`,           // Main line layer
      `geojson-line-layer-highlight-${index}`  // Highlight layer
    ]);
  }, [geojsonSources]);

  // Get interactive layer IDs
  const interactiveLayerIds = useMemo(() => {
    return geojsonSources
      .filter((source) => source.geometryType && ['LineString', 'MultiLineString'].includes(source.geometryType))
      .map((source, index) => `geojson-line-layer-${index}`);
  }, [geojsonSources]);

  // Effect to add layers to the map and manage their order
  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    geojsonSources.forEach((source, index) => {
      // Add the source if it doesn't exist
      if (!map.getSource(source.id)) {
        map.addSource(source.id, source.type === 'geojson' ? { type: 'geojson', data: source.data } : source);
      }

      // Add Outline Layer
      if (!map.getLayer(`geojson-outline-layer-${index}`)) {
        map.addLayer(source.layers[0]);
      }

      // Add Main Line Layer
      if (!map.getLayer(`geojson-line-layer-${index}`)) {
        map.addLayer(source.layers[1]);
      }

      // Add Highlight Layer
      if (!map.getLayer(`geojson-line-layer-highlight-${index}`)) {
        map.addLayer(source.layers[2]);
      }
    });

    // Function to manage layer ordering
    const moveLayersToTop = () => {
      layerIds.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.moveLayer(layerId);
        }
      });
    };

    moveLayersToTop();
    map.on('styledata', moveLayersToTop);

    return () => {
      map.off('styledata', moveLayersToTop);
    };
  }, [mapRef, geojsonSources, layerIds]);

  return {
    geojsonSources,
    interactiveLayerIds,
    layerIds
  };
};

export default useGeoJSONLayers;

// frontend/src/components/DataManagement.js

import React, { useState, useContext, useEffect } from 'react';
import api from '../../../api';
import { AuthContext } from '../../auth/context/AuthContext';
import { SERVICE_TYPES } from '../../../constants/serviceTypes'; // Import constants
import styles from '../styles/ImageUpload.module.css'; // Import as styles

const ImageUpload = ({ services }) => {
    const { auth } = useContext(AuthContext);
    const [serviceId, setServiceId] = useState('');
    const [projectId, setProjectId] = useState('');
    const [projects, setProjects] = useState([]);
    const [images, setImages] = useState([]); // For image uploads
    const [displacementEW, setDisplacementEW] = useState(null); // For Landslide Monitoring
    const [displacementNS, setDisplacementNS] = useState(null); // For Landslide Monitoring
    const [geojsonFile, setGeojsonFile] = useState(null); // For SGAM GeoJSON upload
    const [cameraPosition, setCameraPosition] = useState('right'); // For Tunnel Convergences
    const [message, setMessage] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
    const [isUploading, setIsUploading] = useState(false); // New state to indicate uploading status
    const [availableImages, setAvailableImages] = useState([]); // New state for available images
    const [selectedImages, setSelectedImages] = useState([]); // State for selected images

    // Fetch available images when serviceType is SGAM
    useEffect(() => {
        const fetchAvailableImages = async () => {
            if (serviceType === SERVICE_TYPES.SGAM) {
                try {
                    const res = await api.get('/georeferenced-images/available-images', {
                        headers: { 'Authorization': `Bearer ${auth.token}` }
                    });
                    setAvailableImages(res.data.images);
                } catch (err) {
                    console.error(err);
                    setMessage('Failed to load available images.');
                }
            } else {
                // Clear available images if not SGAM
                setAvailableImages([]);
                setSelectedImages([]);
            }
        };
        fetchAvailableImages();
    }, [serviceType, auth.token]);

    // Fetch projects when serviceId changes
    useEffect(() => {
        const fetchProjects = async () => {
            if(serviceId){
                try {
                    const res = await api.get(`/projects/service/${serviceId}`, {
                        headers: { 'Authorization': `Bearer ${auth.token}` }
                    });
                    setProjects(res.data);
                } catch(err) {
                    console.error(err);
                    setMessage('Failed to load projects.');
                }
            } else {
                setProjects([]);
                setProjectId('');
                setServiceType('');
            }
        };
        fetchProjects();
    }, [serviceId, auth.token]);

    // Fetch serviceType when projectId changes
    useEffect(() => {
        const fetchServiceType = async () => {
            if(projectId){
                try {
                    const res = await api.get(`/projects/${projectId}`, {
                        headers: { 'Authorization': `Bearer ${auth.token}` }
                    });
                    setServiceType(res.data.service.name);
                } catch(err) {
                    console.error(err);
                    setMessage('Failed to fetch service type.');
                }
            } else {
                setServiceType('');
            }
        };
        fetchServiceType();
    }, [projectId, auth.token]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset previous messages
        setMessage('');

        // Validation
        if (!projectId) {
            setMessage('Please select a project.');
            return;
        }

        // Initialize flags for SGAM
        const isGeojsonPresent = geojsonFile !== null;
        const areImagesSelected = selectedImages.length > 0;

        // Additional validations based on service type
        if(serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES){
            if(images.length === 0){
                setMessage('Please choose at least one image.');
                return;
            }
        }

        if(serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
            if(!displacementEW || !displacementNS || !images.length){
                setMessage('Please upload a reference image and both displacement .parquet files.');
                return;
            }
        }

        if(serviceType === SERVICE_TYPES.SGAM){
            if (!isGeojsonPresent && !areImagesSelected) {
                setMessage('Please upload a GeoJSON file and/or select at least one image.');
                return;
            }
            // Validate GeoJSON file extensions if provided
            if(geojsonFile){
                const allowedExtensions = ['.geojson', '.gpkg', '.shp'];
                const hasValidExtension = allowedExtensions.some(ext => geojsonFile.name.toLowerCase().endsWith(ext));
                if(!hasValidExtension){
                    setMessage('GeoJSON file must have one of the following extensions: .geojson, .gpkg, .shp');
                    return;
                }
            }
            // Note: Raster handling is not implemented in the backend routes provided.
            // If raster uploads are required, additional backend routes are needed.
            // For now, assume raster files are managed as existing images and added via add-to-project.
        }

        // Validate based on service type
        if(serviceType === SERVICE_TYPES.ROCKFALL_DETECTION){
            // Ensure filenames follow the required patterns (two-timestamp or single-timestamp)
            const twoTimestampPattern = /^(.+?)_(\d{14})__vs__(\d{14})\.(jpeg|jpg|png|gif)$/i;
            const singleTimestampPattern1 = /^(\d{4}_\d{2}_\d{2}-\d{2}_\d{2}_\d{2})\.(jpeg|jpg|png|gif)$/i;
            const singleTimestampPattern2 = /^(\d{14})\.(jpeg|jpg|png|gif)$/i;
            for(const file of images){
                if(!twoTimestampPattern.test(file.name) && !singleTimestampPattern1.test(file.name) && !singleTimestampPattern2.test(file.name)){
                    setMessage('All image filenames must follow one of the specified Rockfall Detection patterns.');
                    return;
                }
            }
        }

        if(serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES){
            // Ensure a camera position is selected
            if(!cameraPosition){
                setMessage('Please select a camera position.');
                return;
            }

            // Ensure all filenames follow the required pattern
            const regex = /^ts_(\d+(\.\d+)?)_(orizzontale|verticale)\.(jpeg|jpg|png|gif)$/i;
            for(const file of images){
                if(!regex.test(file.name)){
                    setMessage('All image filenames must follow the pattern: ts_<progressiva>_<direction>.<ext> (e.g., ts_17913.6_orizzontale.png)');
                    return;
                }
            }
        }

        if(serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
            // Validate .parquet filenames
            if(displacementEW.name !== 'displacement_EW_raw_.parquet'){
                setMessage('Displacement EW file must be named exactly "displacement_EW_raw_.parquet".');
                return;
            }

            if(displacementNS.name !== 'displacement_NS_raw_.parquet'){
                setMessage('Displacement NS file must be named exactly "displacement_NS_raw_.parquet".');
                return;
            }

            // Ensure exactly one reference image is uploaded
            if(images.length !== 1){
                setMessage('Please upload exactly one reference image for Landslide Monitoring.');
                return;
            }
        }

        // Prepare for API calls
        setIsUploading(true); // Start uploading
        setUploadProgress(0); // Reset progress

        try {
            // Initialize an array to hold all API call promises
            const apiCalls = [];

            // Handle non-SGAM services
            if(serviceType !== SERVICE_TYPES.SGAM){
                const formData = new FormData();

                if(serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES){
                    // Append multiple images
                    for(const file of images){
                        formData.append('images', file);
                    }
                    if(serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES){
                        formData.append('cameraPosition', cameraPosition); // Append camera position
                    }
                } else if(serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
                    // Append reference image and two .parquet files
                    formData.append('referenceImage', images[0]);
                    formData.append('displacementEW', displacementEW);
                    formData.append('displacementNS', displacementNS);
                }

                // Determine endpoint
                let endpoint = '';
                if(serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES){
                    endpoint = `/upload/upload/${projectId}`;
                } else if(serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
                    endpoint = `/landslide/upload/${projectId}`;
                }

                // Push the API call promise
                const uploadPromise = api.post(endpoint, formData, {
                    headers: { 
                        'Authorization': `Bearer ${auth.token}`,
                        // 'Content-Type': 'multipart/form-data' is automatically set by axios when using FormData
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(prev => {
                            // Since SGAM uploads are also ongoing, we need to allocate progress accordingly
                            // Here, we assume non-SGAM services have their own upload progress
                            return percentCompleted; // Overwrite for non-SGAM
                        });
                    }
                }).then(res => {
                    return { type: 'non-sgam', data: res.data };
                }).catch(err => {
                    throw { type: 'non-sgam', error: err.response?.data?.message || 'Failed to upload data.' };
                });

                apiCalls.push(uploadPromise);
            }

            // Handle SGAM: GeoJSON upload
            if(serviceType === SERVICE_TYPES.SGAM && isGeojsonPresent){
                const geojsonFormData = new FormData();
                geojsonFormData.append('geojson', geojsonFile);

                const geojsonUpload = api.post(`/sgam/upload/${projectId}`, geojsonFormData, {
                    headers: { 
                        'Authorization': `Bearer ${auth.token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(prev => Math.min(prev + percentCompleted / 2, 100)); // Allocate half progress to GeoJSON upload
                    }
                }).then(res => {
                    return { type: 'geojson', data: res.data };
                }).catch(err => {
                    throw { type: 'geojson', error: err.response?.data?.message || 'Failed to upload GeoJSON.' };
                });

                apiCalls.push(geojsonUpload);
            }

            // Handle SGAM: Add existing georeferenced images
            if(serviceType === SERVICE_TYPES.SGAM && areImagesSelected){
                const imageAddPayload = {
                    projectId,
                    imageNames: selectedImages,
                    description: '', // You can adjust this if needed
                };

                const imageAdd = api.post('/georeferenced-images/add-to-project', imageAddPayload, {
                    headers: { 
                        'Authorization': `Bearer ${auth.token}`,
                        'Content-Type': 'application/json'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(prev => Math.min(prev + percentCompleted / 2, 100)); // Allocate half progress to image addition
                    }
                }).then(res => {
                    return { type: 'images', data: res.data };
                }).catch(err => {
                    throw { type: 'images', error: err.response?.data?.message || 'Failed to add images to project.' };
                });

                apiCalls.push(imageAdd);
            }

            // Execute all API calls concurrently
            const results = await Promise.all(apiCalls);

            // Aggregate success messages
            let successMessage = 'Data uploaded successfully.';

            results.forEach(result => {
                if(result.type === 'non-sgam'){
                    successMessage += '\nNon-SGAM data uploaded successfully.';
                }
                if(result.type === 'geojson'){
                    successMessage += '\nGeoJSON uploaded successfully.';
                }
                if(result.type === 'images'){
                    successMessage += '\nImages added to project successfully.';
                    if (result.data.overwritten && result.data.overwritten.length > 0) {
                        successMessage += `\nOverwritten Images:\n`;
                        result.data.overwritten.forEach((img, index) => {
                            successMessage += `${index + 1}. ${img.imageUrl}\n`;
                        });
                    }
                }
            });

            setMessage(successMessage);

            // Reset form fields
            setProjectId('');
            setImages([]);
            setDisplacementEW(null);
            setDisplacementNS(null);
            setGeojsonFile(null); // Reset GeoJSON file
            setCameraPosition('right'); // Reset to default
            setServiceType('');
            setUploadProgress(0); // Reset progress
            setSelectedImages([]);
            setAvailableImages([]);

            // Reset file inputs
            if(serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES){
                document.getElementById('imageInput').value = null
            }
            if(serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
                document.getElementById('parquetEWInput').value = null;
                document.getElementById('parquetNSInput').value = null;
            }
            if(serviceType === SERVICE_TYPES.SGAM){
                document.getElementById('geojsonInput').value = null;
                // No rasterInput based on backend routes
            }
        } catch(err) {
            console.error(err);
            if(err.type === 'geojson'){
                setMessage(err.error);
            } else if(err.type === 'images'){
                setMessage(err.error);
            } else if(err.type === 'non-sgam'){
                setMessage(err.error);
            } else {
                setMessage('Failed to upload data.');
            }
        } finally {
            setIsUploading(false); // Upload finished
        }
    };

    // Function to handle image file change with real-time validation
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if(serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES){
            const regex = /^ts_(\d+(\.\d+)?)_(orizzontale|verticale)\.(jpeg|jpg|png|gif)$/i;
            for(const file of files){
                if(!regex.test(file.name)){
                    setMessage('All image filenames must follow the pattern: ts_<progressiva>_<direction>.<ext> (e.g., ts_17913.6_orizzontale.png)');
                    setImages([]);
                    return;
                }
            }
        } else if (serviceType === SERVICE_TYPES.ROCKFALL_DETECTION) {
            // Validate filename patterns for Rockfall Detection
            const twoTimestampPattern = /^(.+?)_(\d{14})__vs__(\d{14})\.(jpeg|jpg|png|gif)$/i;
            const singleTimestampPattern1 = /^(\d{4}_\d{2}_\d{2}-\d{2}_\d{2}_\d{2})\.(jpeg|jpg|png|gif)$/i;
            const singleTimestampPattern2 = /^(\d{14})\.(jpeg|jpg|png|gif)$/i;
            for(const file of files){
                if(!twoTimestampPattern.test(file.name) && !singleTimestampPattern1.test(file.name) && !singleTimestampPattern2.test(file.name)){
                    setMessage('All image filenames must follow one of the specified Rockfall Detection patterns.');
                    setImages([]);
                    return;
                }
            }
        } else if (serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
            // Validate reference image (any image file)
            for(const file of files){
                const allowedTypes = /jpeg|jpg|png|gif/;
                const extname = allowedTypes.test(file.name.toLowerCase());
                const mimetype = allowedTypes.test(file.type);
                if(!extname || !mimetype){
                    setMessage('Only image files are allowed for the reference image.');
                    setImages([]);
                    return;
                }
            }
        }
        setImages(files);
        setMessage('');
    };

    // Function to handle GeoJSON file change for SGAM
    const handleGeojsonChange = (e) => {
        const file = e.target.files[0];
        if(serviceType === SERVICE_TYPES.SGAM){
            if(file){
                const allowedExtensions = ['.geojson', '.gpkg', '.shp'];
                const hasValidExtension = allowedExtensions.some(ext => file.name.toLowerCase().endsWith(ext));
                if(!hasValidExtension){
                    setMessage('GeoJSON file must have one of the following extensions: .geojson, .gpkg, .shp');
                    setGeojsonFile(null);
                    e.target.value = null;
                    return;
                }
                setGeojsonFile(file);
                setMessage('');
            } else {
                setGeojsonFile(null);
            }
        }
    };

    // Function to handle Displacement EW file change
    const handleDisplacementEWChange = (e) => {
        const file = e.target.files[0];
        if(serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
            if(file && file.name !== 'displacement_EW_raw_.parquet'){
                setMessage('Displacement EW file must be named exactly "displacement_EW_raw_.parquet".');
                setDisplacementEW(null);
                e.target.value = null;
                return;
            }
            if(file && file.type !== 'application/octet-stream' && file.type !== 'application/x-parquet' && !file.name.endsWith('.parquet')){
                setMessage('Only .parquet files are allowed for displacement EW.');
                setDisplacementEW(null);
                e.target.value = null;
                return;
            }
        }
        setDisplacementEW(file);
        setMessage('');
    };

    // Function to handle Displacement NS file change
    const handleDisplacementNSChange = (e) => {
        const file = e.target.files[0];
        if(serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING){
            if(file && file.name !== 'displacement_NS_raw_.parquet'){
                setMessage('Displacement NS file must be named exactly "displacement_NS_raw_.parquet".');
                setDisplacementNS(null);
                e.target.value = null;
                return;
            }
            if(file && file.type !== 'application/octet-stream' && file.type !== 'application/x-parquet' && !file.name.endsWith('.parquet')){
                setMessage('Only .parquet files are allowed for displacement NS.');
                setDisplacementNS(null);
                e.target.value = null;
                return;
            }
        }
        setDisplacementNS(file);
        setMessage('');
    };

    return (
        <div className={styles.container}>
            <h3>Upload{serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES || serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING || serviceType === SERVICE_TYPES.SGAM ? 's' : ''} to Project</h3>
            {message && <p className={styles.message}>{message}</p>}
            <form onSubmit={handleSubmit} className={styles.form} encType="multipart/form-data">
                <div className={styles.formGroup}>
                    <label htmlFor="service">Select Service:</label>
                    <select 
                        id="service" 
                        value={serviceId} 
                        onChange={(e) => setServiceId(e.target.value)}
                        required
                        className={styles.select}
                    >
                        <option value="">-- Select Service --</option>
                        {services.map(service => (
                            <option key={service._id} value={service._id}>{service.name}</option>
                        ))}
                    </select>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="project">Select Project:</label>
                    <select 
                        id="project" 
                        value={projectId} 
                        onChange={(e) => setProjectId(e.target.value)}
                        required
                        disabled={!serviceId}
                        className={styles.select}
                    >
                        <option value="">-- Select Project --</option>
                        {projects.map(project => (
                            <option key={project._id} value={project._id}>{project.name}</option>
                        ))}
                    </select>
                </div>
                
                {/* Image Upload Section */}
                {(serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES || serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING) && (
                    <div className={styles.formGroup}>
                        <label htmlFor="imageInput">Choose Image{ (serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES || serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING) ? 's' : ''}:</label>
                        <input 
                            type="file" 
                            id="imageInput"
                            accept="image/*" 
                            onChange={handleImageChange}
                            required={serviceType !== SERVICE_TYPES.LANDSLIDE_MONITORING}
                            multiple={(serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES || serviceType === SERVICE_TYPES.ROCKFALL_DETECTION)}
                            name={ (serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES || serviceType === SERVICE_TYPES.ROCKFALL_DETECTION) ? 'images' : 'image' } // Added name attribute
                            className={styles.fileInput}
                        />
                    </div>
                )}

                {/* Parquet Files Upload Section for Landslide Monitoring */}
                {serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING && (
                    <>
                        <div className={styles.formGroup}>
                            <label htmlFor="parquetEWInput">Displacement EW (.parquet):</label>
                            <input 
                                type="file" 
                                id="parquetEWInput"
                                accept=".parquet" 
                                onChange={handleDisplacementEWChange}
                                required
                                name="displacementEW"
                                className={styles.fileInput}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="parquetNSInput">Displacement NS (.parquet):</label>
                            <input 
                                type="file" 
                                id="parquetNSInput"
                                accept=".parquet" 
                                onChange={handleDisplacementNSChange}
                                required
                                name="displacementNS"
                                className={styles.fileInput}
                            />
                        </div>
                    </>
                )}

                {/* GeoJSON File Upload Section for SGAM */}
                {serviceType === SERVICE_TYPES.SGAM && (
                    <div className={styles.formGroup}>
                        <label htmlFor="geojsonInput">Choose GeoJSON/GPKG/SHP File (optional):</label>
                        <input 
                            type="file" 
                            id="geojsonInput"
                            accept=".geojson,.gpkg,.shp" 
                            onChange={handleGeojsonChange}
                            name="geojson"
                            className={styles.fileInput}
                        />
                    </div>
                )}

                {/* Image Selection Section for SGAM */}
                {serviceType === SERVICE_TYPES.SGAM && (
                    <div className={styles.formGroup}>
                        <label htmlFor="availableImages">Select Images to Add:</label>
                        {availableImages.length > 0 ? (
                            <div className={styles.imageList}>
                                {availableImages.map((imageName, index) => (
                                    <div key={index} className={styles.imageItem}>
                                        <input
                                            type="checkbox"
                                            id={`image-${index}`}
                                            name="selectedImages"
                                            value={imageName}
                                            checked={selectedImages.includes(imageName)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedImages([...selectedImages, imageName]);
                                                } else {
                                                    setSelectedImages(selectedImages.filter(name => name !== imageName));
                                                }
                                            }}
                                        />
                                        <label htmlFor={`image-${index}`}>{imageName}</label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No available images found.</p>
                        )}
                    </div>
                )}

                {/* Conditional Fields Based on Service Type */}
                {serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES && images.length > 0 && (
                    <div className={styles.formGroup}>
                        <label htmlFor="cameraPosition">Select Camera Position:</label>
                        <select 
                            id="cameraPosition" 
                            value={cameraPosition} 
                            onChange={(e) => setCameraPosition(e.target.value)}
                            required
                            className={styles.select}
                        >
                            <option value="right">Right</option>
                            <option value="left">Left</option>
                        </select>
                        <small>All selected images will be assigned this camera position.</small>
                    </div>
                )}

                {/* Progress Bar */}
                {isUploading && (
                    <div className={styles.progressBarContainer}>
                        <div className={styles.progressBar} style={{ width: `${uploadProgress}%` }}>
                            {uploadProgress}%
                        </div>
                    </div>
                )}

                <button type="submit" className={styles.submitButton} disabled={isUploading}>
                    {isUploading ? 'Uploading...' : `Upload${ (serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES || serviceType === SERVICE_TYPES.ROCKFALL_DETECTION || serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING || serviceType === SERVICE_TYPES.SGAM) ? 's' : ''}`}
                </button>
            </form>

            {/* Filename Information for Different Services */}
            {serviceType === SERVICE_TYPES.TUNNEL_CONVERGENCES && (
                <div className={styles.filenameInfo}>
                    <p><strong>Note:</strong> For Tunnel Convergences, please ensure your image filenames follow the pattern:</p>
                    <p><code>ts_&lt;progressiva&gt;_&lt;direction&gt;.&lt;ext&gt;</code></p>
                    <p>Example: <code>ts_17913.6_orizzontale.png</code></p>
                </div>
            )}
            {serviceType === SERVICE_TYPES.ROCKFALL_DETECTION && (
                <div className={styles.filenameInfo}>
                    <p><strong>Note:</strong> For Rockfall Detection, please ensure your image filenames follow one of the patterns:</p>
                    <p><code>&lt;somename&gt;_YYYYMMDDHHMMSS__vs__YYYYMMDDHHMMSS.&lt;ext&gt;</code></p>
                    <p>Example: <code>rockfall_20240701123055__vs__20240520063135.jpg</code></p>
                    <p><code>YYYY_MM_DD-HH_MM_SS.&lt;ext&gt;</code></p>
                    <p>Example: <code>2024_09_24-19_20_12.jpg</code></p>
                    <p><code>YYYYMMDDHHMMSS.&lt;ext&gt;</code></p>
                    <p>Example: <code>20240516044744.jpg</code></p>
                </div>
            )}
            {serviceType === SERVICE_TYPES.LANDSLIDE_MONITORING && (
                <div className={styles.filenameInfo}>
                    <p><strong>Note:</strong> For Landslide Monitoring, please ensure you upload:</p>
                    <ul>
                        <li>One reference image (any image format).</li>
                        <li>Two .parquet files named exactly:
                            <ul>
                                <li><code>displacement_EW_raw_.parquet</code></li>
                                <li><code>displacement_NS_raw_.parquet</code></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            )}
            {serviceType === SERVICE_TYPES.SGAM && (
                <div className={styles.filenameInfo}>
                    <p><strong>Note:</strong> For SGAM, you can upload a GeoJSON/GPKG/SHP file and/or select existing images to add to the project.</p>
                    <ul>
                        <li><strong>GeoJSON/GPKG/SHP:</strong> Required if you're uploading vector data.</li>
                        <li><strong>Images:</strong> Select from the list of available georeferenced images to add to the project.</li>
                    </ul>
                    <p>Ensure that your GeoJSON files have the correct extensions:</p>
                    <ul>
                        <li><code>.geojson</code></li>
                        <li><code>.gpkg</code></li>
                        <li><code>.shp</code></li>
                    </ul>
                </div>
            )}
        </div>
    );

};

export default ImageUpload;

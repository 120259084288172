// src/components/RockfallProjectDetail/RockfallProjectDetail.js

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../auth/context/AuthContext';
import styles from '../../common/styles/ProjectDetail.module.css';
import RockfallImageViewer from './RockfallImageViewer';
import useRockfallImages from '../hooks/useRockfallImages';
import useRockfallCycle from '../hooks/useRockfallCycle';
import { FaCalendarAlt, FaFlag, FaClock } from 'react-icons/fa';

const RockfallProjectDetail = ({ project }) => {
  const projectId = project._id;
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  const { loading, error, imageGroups } = useRockfallImages(projectId, auth.token);
  const {
    currentIndex,
    cycleStep,
    displayedImage,
    handleNextCycle,
    handlePrevCycle,
    setCurrentIndex,
    resetCycle,
  } = useRockfallCycle(imageGroups);

  const [imageAspectRatio, setImageAspectRatio] = useState(null);

  const handleSliderChange = (e) => {
    const newIndex = Number(e.target.value);
    setCurrentIndex(newIndex);
    resetCycle();
  };

  const handleImageLoad = (aspectRatio) => {
    setImageAspectRatio(aspectRatio);
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
        <p className={styles.loading}>Loading images...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
        <p className={styles.error}>{error}</p>
      </div>
    );
  }

  if (!displayedImage) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
        <p className={styles.error}>No images available.</p>
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      style={imageAspectRatio ? { '--aspect-ratio': imageAspectRatio } : {}}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>{project.name}</h2>
        <button onClick={() => navigate(-1)} className={styles.backButton}>
          Back
        </button>
      </div>

      <RockfallImageViewer
        displayedImage={displayedImage}
        cycleStep={cycleStep}
        onNextCycle={handleNextCycle}
        onPrevCycle={handlePrevCycle}
        onImageLoad={handleImageLoad}
      />

      <div className={styles.sliderContainer}>
        <input
          type="range"
          min={0}
          max={imageGroups.length-1}
          value={currentIndex}
          onChange={handleSliderChange}
          className={styles.slider}
        />
        <div className={styles.timestampContainer}>
          {displayedImage.fromTimestamp && displayedImage.toTimestamp ? (
            <>
              <div className={styles.timestampBox}>
                <div className={styles.iconLabelRow}>
                  <FaCalendarAlt className={styles.icon} />
                  <span className={styles.timestampLabel}>From:</span>
                </div>
                <div className={styles.timestampValue}>
                  {new Date(displayedImage.fromTimestamp).toLocaleString()}
                </div>
              </div>
              <div className={styles.timestampBox}>
                <div className={styles.iconLabelRow}>
                  <FaFlag className={styles.icon} />
                  <span className={styles.timestampLabel}>To:</span>
                </div>
                <div className={styles.timestampValue}>
                  {new Date(displayedImage.toTimestamp).toLocaleString()}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.timestampBox}>
                <div className={styles.iconLabelRow}>
                  <FaClock className={styles.icon} />
                  <span className={styles.timestampLabel}>Timestamp:</span>
                </div>
                <div className={styles.timestampValue}>
                  {new Date(displayedImage.timestamp).toLocaleString()}
                </div>
              </div>
              <div className={styles.transparentBox}></div>
            </>
          )}
        </div>
      </div>

      <div className={styles.keyboardHint}>
        <p>Use keys '1' and '2' to switch between pre-post-result images.</p>
      </div>
    </div>
  );
};

export default RockfallProjectDetail;

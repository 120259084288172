// src/features/projects/sgam/components/mapcomponents/PegmanControl.js

import React from 'react';
import pegmanImg from '../../../../../assets/pegman_image.png';

const PegmanControl = ({
  isPegmanDragging,
  pegmanScreenPosition,
  handlePegmanMouseDown,
  styles,
}) => {
  return (
    <div
      className={`${styles.pegmanContainer} ${isPegmanDragging ? styles.active : ''}`}
      style={{
        top: pegmanScreenPosition ? `${pegmanScreenPosition.y}px` : 'auto',
        bottom: pegmanScreenPosition ? 'auto' : '170px',
        left: pegmanScreenPosition ? `${pegmanScreenPosition.x}px` : '15px',
        cursor: isPegmanDragging ? 'grabbing' : 'pointer',
        position: 'absolute',
        zIndex: 1000,
        transition: pegmanScreenPosition ? 'none' : 'all 0.2s ease',
      }}
      onMouseDown={handlePegmanMouseDown}
    >
      <img 
        src={pegmanImg} 
        alt="Pegman" 
        className={styles.pegmanImage}
        style={{
          pointerEvents: 'none', // Prevent image from interfering with drag
          userSelect: 'none',
        }}
      />
    </div>
  );
};

export default PegmanControl;

// src/features/projects/sgam/components/mapcomponents/StreetViewModal.js

import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../components/ErrorBoundary'; // Adjust the path as needed

const StreetViewModal = ({ streetViewLocation, onClose, GOOGLE_MAPS_API_KEY, styles }) => {
  return (
    <div className={styles.streetViewModal}>
      <div className={styles.streetViewContent}>
        <button className={styles.closeStreetViewButton} onClick={onClose}>
          Close
        </button>
        <ErrorBoundary>
          <iframe
            title="Google Street View"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/streetview?key=${GOOGLE_MAPS_API_KEY}&location=${streetViewLocation.lat},${streetViewLocation.lng}&heading=210&pitch=0&fov=35`}
            allowFullScreen
            allow="accelerometer; gyroscope;"
          ></iframe>
        </ErrorBoundary>
      </div>
    </div>
  );
};

StreetViewModal.propTypes = {
  streetViewLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  GOOGLE_MAPS_API_KEY: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
};

export default StreetViewModal;

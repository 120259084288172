import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import '../../styles/ProjectInfoPopup.css';

const ProjectInfoPopup = ({ onClose }) => {
  const popupRef = useRef(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    // Find the map container dynamically
    const mapContainer = document.querySelector('.map-container');
    mapContainerRef.current = mapContainer;

    // Position the popup relative to the map container
    if (popupRef.current && mapContainer) {
      const mapRect = mapContainer.getBoundingClientRect();
      popupRef.current.style.position = 'fixed';
      popupRef.current.style.top = `${mapRect.top + 10}px`;
      popupRef.current.style.left = `${mapRect.left + 10}px`;
      popupRef.current.style.width = `${mapRect.width - 20}px`;
      popupRef.current.style.maxWidth = '600px';
      popupRef.current.style.margin = '0 auto';
    }

    // Add click outside listener
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Use createPortal to render outside the current DOM hierarchy
  return createPortal(
    <div ref={popupRef} className="project-info-overlay">
      <div className="project-info-popup">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h3>SGAM Project Information</h3>
        <div className="info-section">
          <h4>About SGAM</h4>
          <p>SGAM (Structural Geological Asset Management) is a comprehensive system for monitoring and assessing infrastructure assets.</p>
        </div>
        <div className="info-section">
          <h4>Risk Assessment</h4>
          <p>The risk levels are categorized as follows:</p>
          <ul className="risk-list">
            <li><span className="hazard-level" style={{backgroundColor: '#1a9850', color: '#FFFFFF'}}>Low</span> - Minimal risk, regular monitoring</li>
            <li><span className="hazard-level" style={{backgroundColor: '#a3b61d', color: '#FFFFFF'}}>Medium-Low</span> - Slight risk, increased monitoring</li>
            <li><span className="hazard-level" style={{backgroundColor: '#ffe41a', color: '#000000'}}>Medium</span> - Moderate risk, regular inspection required</li>
            <li><span className="hazard-level" style={{backgroundColor: '#f0801a', color: '#FFFFFF'}}>Medium-High</span> - Significant risk, frequent inspection needed</li>
            <li><span className="hazard-level" style={{backgroundColor: '#d73027', color: '#FFFFFF'}}>High</span> - High risk, immediate attention required</li>
          </ul>
        </div>
        <div className="info-section">
          <h4>Structure Types</h4>
          <ul className="structure-list">
            <li><span className="hazard-level" style={{backgroundColor: '#808080', color: '#FFFFFF'}}>Road</span> - Road infrastructure</li>
            <li><span className="hazard-level" style={{backgroundColor: '#4682b4', color: '#FFFFFF'}}>Bridge</span> - Bridge infrastructure</li>
            <li><span className="hazard-level" style={{backgroundColor: '#2F2F2F', color: '#FFFFFF'}}>Tunnel</span> - Tunnel infrastructure</li>
          </ul>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ProjectInfoPopup;
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../api';
import styles from '../styles/ResetPassword.module.css';
import { FaLock, FaEye, FaEyeSlash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [form, setForm] = useState({
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Password validation states
    const [validations, setValidations] = useState({
        minLength: false,
        hasNumber: false,
        hasSpecial: false,
        passwordsMatch: false
    });

    const validatePassword = (password) => {
        setValidations(prev => ({
            ...prev,
            minLength: password.length >= 8,
            hasNumber: /\d/.test(password),
            hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            passwordsMatch: password === form.confirmPassword
        }));
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
        
        if (name === 'password') {
            validatePassword(value);
        }
        if (name === 'confirmPassword') {
            setValidations(prev => ({
                ...prev,
                passwordsMatch: value === form.password
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const token = searchParams.get('token');
        if (!token) {
            setError('Invalid reset token. Please try requesting a new password reset.');
            setLoading(false);
            return;
        }

        try {
            const response = await api.post('/auth/reset-password', {
                token,
                password: form.password
            });
            
            if (response.data.passwordReset) {
                navigate('/login', { 
                    state: { 
                        passwordReset: true,
                        message: "Password successfully reset. Please log in with your new password." 
                    } 
                });
            } else {
                navigate('/login?status=success&message=password_reset');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to reset password. Please try again.');
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Reset Your Password</h2>
            {error && <p className={styles.errorMessage}>{error}</p>}
            
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="password">New Password</label>
                    <div className={styles.inputWrapper}>
                        <FaLock className={styles.inputIcon} />
                        <input 
                            type={showPassword ? "text" : "password"}
                            name="password" 
                            id="password"
                            placeholder="Enter your new password" 
                            value={form.password}
                            onChange={handleChange}
                            required 
                            className={styles.input}
                            disabled={loading}
                        />
                        <button 
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className={styles.showPasswordButton}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <div className={styles.inputWrapper}>
                        <FaLock className={styles.inputIcon} />
                        <input 
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword" 
                            id="confirmPassword"
                            placeholder="Confirm your new password" 
                            value={form.confirmPassword}
                            onChange={handleChange}
                            required 
                            className={styles.input}
                            disabled={loading}
                        />
                        <button 
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className={styles.showPasswordButton}
                        >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>

                <div className={styles.passwordRequirements}>
                    <h3>Password Requirements:</h3>
                    <ul>
                        <li className={validations.minLength ? styles.valid : styles.invalid}>
                            {validations.minLength ? <FaCheckCircle /> : <FaTimesCircle />}
                            At least 8 characters
                        </li>
                        <li className={validations.hasNumber ? styles.valid : styles.invalid}>
                            {validations.hasNumber ? <FaCheckCircle /> : <FaTimesCircle />}
                            Contains a number
                        </li>
                        <li className={validations.hasSpecial ? styles.valid : styles.invalid}>
                            {validations.hasSpecial ? <FaCheckCircle /> : <FaTimesCircle />}
                            Contains a special character
                        </li>
                        <li className={validations.passwordsMatch ? styles.valid : styles.invalid}>
                            {validations.passwordsMatch ? <FaCheckCircle /> : <FaTimesCircle />}
                            Passwords match
                        </li>
                    </ul>
                </div>

                <button 
                    type="submit" 
                    className={`${styles.submitButton} ${loading ? styles.loading : ''}`}
                    disabled={loading || !Object.values(validations).every(Boolean)}
                >
                    {loading ? 'Resetting Password...' : 'Reset Password'}
                </button>
            </form>
        </div>
    );
};

export default ResetPassword; 
// src/components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from '../styles/Layout.module.css'; // Import as styles

const Layout = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;

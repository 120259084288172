import React from 'react';
import PropTypes from 'prop-types';
import { basemapOptions } from '../../utils/basemaps';
import styles from '../../styles/SgamPage.module.css';
import SettingsPopup from './SettingsPopup';

const MapControls = ({
  isTerrainEnabled,
  isBuildingsEnabled,
  isSettingsOpen,
  selectedBasemap,
  basemapOpacity,
  onTerrainToggle,
  onBuildingsToggle,
  onBasemapChange,
  onOpacityChange,
  onSettingsToggle,
}) => {
  return (
    <>
      <div className={styles.customControls}>
        {/* Terrain Toggle */}
        <label className={styles.controlLabel3d}>
          <input
            type="checkbox"
            checked={isTerrainEnabled}
            onChange={(e) => onTerrainToggle(e.target.checked)}
          />
          3D Terrain
        </label>

        {/* 3D Buildings Toggle */}
        <label className={styles.controlLabel3d}>
          <input
            type="checkbox"
            checked={isBuildingsEnabled}
            onChange={(e) => onBuildingsToggle(e.target.checked)}
          />
          3D Buildings
        </label>

        {/* Basemap Switcher */}
        <select
          className={styles.basemapSelector}
          value={selectedBasemap.id}
          onChange={(e) => {
            const selected = basemapOptions.find(
              (bm) => bm.id === e.target.value
            );
            if (selected) {
              onBasemapChange(selected);
            }
          }}
          title="Select Basemap"
        >
          {basemapOptions.map((basemap) => (
            <option key={basemap.id} value={basemap.id}>
              {basemap.name}
            </option>
          ))}
        </select>
      </div>

      {/* Settings Popup */}
      {isSettingsOpen && (
        <div className={styles.settingsPopup}>
          <SettingsPopup
            basemaps={basemapOptions}
            selectedBasemap={selectedBasemap}
            onBasemapChange={onBasemapChange}
            basemapOpacity={basemapOpacity}
            onOpacityChange={onOpacityChange}
            onClose={onSettingsToggle}
          />
        </div>
      )}
    </>
  );
};

MapControls.propTypes = {
  isTerrainEnabled: PropTypes.bool.isRequired,
  isBuildingsEnabled: PropTypes.bool.isRequired,
  isSettingsOpen: PropTypes.bool.isRequired,
  selectedBasemap: PropTypes.object.isRequired,
  basemapOpacity: PropTypes.number.isRequired,
  onTerrainToggle: PropTypes.func.isRequired,
  onBuildingsToggle: PropTypes.func.isRequired,
  onBasemapChange: PropTypes.func.isRequired,
  onOpacityChange: PropTypes.func.isRequired,
  onSettingsToggle: PropTypes.func.isRequired,
};

export default MapControls;

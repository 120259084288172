import { useState, useCallback } from 'react';
import { basemapOptions } from '../utils/basemaps';

export const useMapControls = (initialBasemap, initialOpacity) => {
  const [isTerrainEnabled, setIsTerrainEnabled] = useState(false);
  const [isBuildingsEnabled, setIsBuildingsEnabled] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectedBasemap, setSelectedBasemap] = useState(initialBasemap);
  const [basemapOpacity, setBasemapOpacity] = useState(initialOpacity);

  const toggleSettings = useCallback(() => {
    setIsSettingsOpen(prev => !prev);
  }, []);

  const handleBasemapChange = useCallback((newBasemap) => {
    const selected = typeof newBasemap === 'string' 
      ? basemapOptions.find(bm => bm.id === newBasemap)
      : newBasemap;
      
    if (selected) {
      setSelectedBasemap(selected);
    }
  }, []);

  const handleOpacityChange = useCallback((newOpacity) => {
    setBasemapOpacity(newOpacity);
  }, []);

  const toggleTerrain = useCallback((enabled) => {
    setIsTerrainEnabled(enabled);
  }, []);

  const toggleBuildings = useCallback((enabled) => {
    setIsBuildingsEnabled(enabled);
  }, []);

  return {
    // State
    isTerrainEnabled,
    isBuildingsEnabled,
    isSettingsOpen,
    selectedBasemap,
    basemapOpacity,

    // Actions
    toggleSettings,
    handleBasemapChange,
    handleOpacityChange,
    toggleTerrain,
    toggleBuildings,
  };
};

export default useMapControls;

// frontend/src/components/ProjectCreation.js

import React, { useState, useContext, useEffect } from 'react';
import api from '../../../api';
import { AuthContext } from '../../auth/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/ProjectCreation.module.css';

const ProjectCreation = ({ services }) => {
    const { auth } = useContext(AuthContext);
    const [serviceId, setServiceId] = useState('');
    const [projectName, setProjectName] = useState('');
    const [message, setMessage] = useState('');
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    // Fetch existing projects on component mount
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const res = await api.get('/projects', {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });
                setProjects(res.data);
            } catch (err) {
                console.error(err);
                setMessage(err.response?.data?.message || 'Failed to fetch projects.');
            }
        };
        fetchProjects();
    }, [auth.token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!serviceId || !projectName) {
            setMessage('Please select a service and enter a project name.');
            return;
        }

        try {
            const res = await api.post(`/projects`, {
                serviceId,
                name: projectName
            }, {
                headers: { 'Authorization': `Bearer ${auth.token}` }
            });

            setMessage(`Project "${res.data.name}" created successfully.`);
            setServiceId('');
            setProjectName('');
            setProjects([...projects, res.data]); // Update the projects list
        } catch(err) {
            console.error(err);
            setMessage(err.response?.data?.message || 'Failed to create project.');
        }
    };

    const handleDelete = async (projectId) => {
        if(!window.confirm('Are you sure you want to delete this project? This action cannot be undone.')) {
            return;
        }

        try {
            await api.delete(`/projects/${projectId}`, {
                headers: { 'Authorization': `Bearer ${auth.token}` }
            });
            setMessage('Project deleted successfully.');
            // Remove the deleted project from the list
            setProjects(projects.filter(project => project._id !== projectId));
        } catch(err) {
            console.error(err);
            setMessage(err.response?.data?.message || 'Failed to delete project.');
        }
    };

    return (
        <div className={styles.container}>
            <h3>Create New Project</h3>
            {message && <p className={styles.message}>{message}</p>}
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="service">Select Service:</label>
                    <select 
                        id="service" 
                        value={serviceId} 
                        onChange={(e) => setServiceId(e.target.value)}
                        required
                        className={styles.select}
                    >
                        <option value="">-- Select Service --</option>
                        {services.map(service => (
                            <option key={service._id} value={service._id}>{service.name}</option>
                        ))}
                    </select>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="projectName">Project Name:</label>
                    <input 
                        type="text" 
                        id="projectName" 
                        value={projectName} 
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                        placeholder="Enter project name"
                        className={styles.input}
                    />
                </div>
                <button type="submit" className={styles.submitButton}>Create Project</button>
            </form>

            <h3>Existing Projects</h3>
            <table className={styles.projectTable}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Service</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.length === 0 ? (
                        <tr>
                            <td colSpan="3">No projects found.</td>
                        </tr>
                    ) : (
                        projects.map(project => (
                            <tr key={project._id}>
                                <td>{project.name}</td>
                                <td>{project.service.name}</td>
                                <td>
                                    <button 
                                        onClick={() => handleDelete(project._id)} 
                                        className={styles.deleteButton}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ProjectCreation;

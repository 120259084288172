// src/components/AdminDashboard.js
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/context/AuthContext';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import api from '../../../api';
import UserManagement from './UserManagement';
import ProjectCreation from './ProjectCreation';
import ImageUpload from './ImageUpload';
import styles from '../styles/AdminDashboard.module.css'; // Import CSS Module

const AdminDashboard = () => {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const res = await api.get(`/services`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });
                setServices(res.data);
                setLoading(false);
            } catch(err) {
                console.error(err);
                setError('Failed to load services.');
                setLoading(false);
            }
        };
        fetchServices();
    }, [auth.token]);

    // Remove the handleLogout function since we're replacing the Logout button
    /*
    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    */

    if (loading) {
        return <div className={styles.container}><p>Loading admin dashboard...</p></div>;
    }

    if (error) {
        return <div className={styles.container}><p className={styles.error}>{error}</p></div>;
    }

    return (
        <div className={styles.container}>
            {/* Wrap the title and back button in a header div */}
            <div className={styles.header}>
                <h2 className={styles.title}>Admin Dashboard</h2>
                {/* Replace Logout button with Back button */}
                <button onClick={() => navigate('/')} className={styles.backButton}>
                    Back
                </button>
            </div>
            <nav className={styles.nav}>
                <ul>
                    <li><Link to="users" className={styles.navLink}>User Management</Link></li>
                    <li><Link to="create-project" className={styles.navLink}>Create Project</Link></li>
                    <li><Link to="upload-image" className={styles.navLink}>Upload Data</Link></li>
                </ul>
            </nav>
            <div className={styles.content}>
                <Routes>
                    <Route path="users" element={<UserManagement />} />
                    <Route path="create-project" element={<ProjectCreation services={services} />} />
                    <Route path="upload-image" element={<ImageUpload services={services} />} />
                </Routes>
            </div>
        </div>
    );
};

export default AdminDashboard;

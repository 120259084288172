// src/features/projects/sgam/hooks/useMapEventListeners.js

import { useEffect } from 'react';

const useMapEventListeners = (mapRef, layerIds) => {
  useEffect(() => {
    const map = mapRef.current && mapRef.current.getMap();
    if (!map) return;

    const handleMouseEnter = () => {
      map.getCanvas().style.cursor = 'pointer';
    };

    const handleMouseLeave = () => {
      map.getCanvas().style.cursor = '';
    };

    const attachEventListeners = () => {
      layerIds.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.on('mouseenter', layerId, handleMouseEnter);
          map.on('mouseleave', layerId, handleMouseLeave);
          // map.on('mousemove', layerId, handleMouseMove);
        } else {
          map.once('idle', attachEventListeners);
        }
      });
    };

    attachEventListeners();

    return () => {
      layerIds.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.off('mouseenter', layerId, handleMouseEnter);
          map.off('mouseleave', layerId, handleMouseLeave);
          // map.off('mousemove', layerId, handleMouseMove);
        }
      });
    };
  }, [layerIds, mapRef]);
};

export default useMapEventListeners;

// src/features/projects/sgam/components/mapcomponents/LegendControl.js

import React from 'react';
import styles from '../../styles/LegendControl.module.css';
import { getColor, formatPropertyName } from '../../utils/mapUtils';

const LegendControl = ({ featureKey }) => {
  if (!featureKey) return null;

  // Define legend items based on featureKey or other criteria
  const isStructure = featureKey.toLowerCase() === 'structure';
  const legendItems = isStructure
    ? [
        { color: '#808080', label: 'Road' },
        { color: '#4682b4', label: 'Bridge' },
        { color: '#2F2F2F', label: 'Tunnel' },
      ]
    : [
        { color: '#1a9850', label: 'Low' },
        { color: '#a3b61d', label: 'Medium-Low' },
        { color: '#ffe41a', label: 'Medium' },
        { color: '#f0801a', label: 'Medium-High' },
        { color: '#d73027', label: 'High' },
      ];

  return (
    <div className={styles.legend}>
      <h4>{formatPropertyName(featureKey)} Levels</h4>
      {legendItems.map((item, index) => (
        <div key={index} className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{ backgroundColor: item.color }}
          ></span>
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default LegendControl;

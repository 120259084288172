import React, { useState } from 'react';
import api from '../../../api';
import { Link } from 'react-router-dom';
import styles from '../styles/ForgotPassword.module.css';
import { FaEnvelope } from 'react-icons/fa';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetSent, setResetSent] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        
        try {
            await api.post('/auth/forgot-password', { email });
            setResetSent(true);
            setSuccess('Password reset instructions have been sent to your email.');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to send reset instructions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Reset Password</h2>
            {error && <p className={styles.errorMessage}>{error}</p>}
            {success && <p className={styles.successMessage}>{success}</p>}
            
            {resetSent ? (
                <div className={styles.verificationMessage}>
                    <FaEnvelope className={styles.verificationIcon} />
                    <h3>Check Your Email</h3>
                    <p>We've sent password reset instructions to <strong>{email}</strong></p>
                    <p>Please check your email and follow the instructions to reset your password.</p>
                    <p className={styles.resendLink}>
                        Didn't receive the email? <button 
                            onClick={handleSubmit} 
                            className={styles.resendButton}
                            disabled={loading}
                        >
                            Resend reset instructions
                        </button>
                    </p>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Email Address</label>
                        <div className={styles.inputWrapper}>
                            <FaEnvelope className={styles.inputIcon} />
                            <input 
                                type="email" 
                                name="email" 
                                id="email"
                                placeholder="Enter your email address" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required 
                                className={styles.input}
                                disabled={loading}
                            />
                        </div>
                        <p className={styles.helpText}>
                            Enter the email address associated with your account, and we'll send you instructions to reset your password.
                        </p>
                    </div>

                    <button 
                        type="submit" 
                        className={`${styles.submitButton} ${loading ? styles.loading : ''}`}
                        disabled={loading || !email}
                    >
                        {loading ? 'Sending Instructions...' : 'Send Reset Instructions'}
                    </button>
                </form>
            )}

            <p className={styles.loginLink}>
                Remember your password? <Link to="/login">Login here</Link>
            </p>
        </div>
    );
};

export default ForgotPassword; 
// src/components/TunnelProjectDetail/hooks/useTunnelImages.js

import { useState, useEffect } from 'react';
import api from '../../../../api';

const useTunnelImages = (projectId, authToken, directionFilter, cameraPositionFilter) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      setError('');
      try {
        const params = {};
        if (directionFilter !== 'All') {
          params.direction = directionFilter;
        }
        if (cameraPositionFilter !== 'All') {
          params.cameraPosition = cameraPositionFilter;
        }

        const imagesRes = await api.get(
          `/projects/project/${projectId}/images`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
            params: params,
          }
        );

        let fetchedImages = imagesRes.data;
        console.log('Fetched Images:', fetchedImages);

        // Sort images by progressiva ascending
        fetchedImages.sort((a, b) => a.progressiva - b.progressiva);

        setImages(fetchedImages);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('Failed to load images.');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [projectId, authToken, directionFilter, cameraPositionFilter]);

  return { loading, error, images };
};

export default useTunnelImages;

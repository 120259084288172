// src/features/projects/sgam/components/mapcomponents/CustomImageLayer.js

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const CustomImageLayer = ({
  projectId,
  imageName,
  imageBoundingBox,
  isImageEnabled,
  basemapOpacity,
  backendUrl,
  mapRef,
}) => {
  const isUnmountingRef = useRef(false);

  useEffect(() => {
    return () => {
      isUnmountingRef.current = true;
    };
  }, []);

  useEffect(() => {
    const map = mapRef.current && mapRef.current.getMap();
  
    if (!map || !map.getStyle) return;

    const addCustomImageLayer = () => {
      // Remove existing layer first to prevent duplicates
      if (map.getLayer('custom-tiles-layer')) {
        map.removeLayer('custom-tiles-layer');
      }
      if (map.getSource('custom-tiles')) {
        map.removeSource('custom-tiles');
      }

      // Only add if image is enabled and all required props are present
      if (!isImageEnabled || !projectId || !imageName || !imageBoundingBox) return;

      // Define the raster source
      map.addSource('custom-tiles', {
        type: 'raster',
        tiles: [
          `${backendUrl}/api/georeferenced-images/${projectId}/tiles/${imageName}/{z}/{x}/{y}.png`,
        ],
        tileSize: 256,
        bounds: imageBoundingBox,
        minzoom: 5,
        maxzoom: 15,
      });

      // Find the first symbol layer to place the custom image layer below labels
      const layers = map.getStyle().layers;
      let firstSymbolId = null;
      for (const layer of layers) {
        if (layer.type === 'symbol') {
          firstSymbolId = layer.id;
          break;
        }
      }
      if (map.getStyle().name === 'Mapbox Satellite Streets') {
        firstSymbolId = 'tunnel-minor-case';
      }

      // Add the raster layer before the first symbol layer
      if (firstSymbolId) {
        map.addLayer(
          {
            id: 'custom-tiles-layer',
            type: 'raster',
            source: 'custom-tiles',
            minzoom: 5,
            maxzoom: 15,
            paint: {
              'raster-opacity': basemapOpacity,
            },
          },
          firstSymbolId
        );
      } else {
        map.addLayer({
          id: 'custom-tiles-layer',
          type: 'raster',
          source: 'custom-tiles',
          paint: {
            'raster-opacity': basemapOpacity,
          },
        });
      }
    };

    // Handle style load and layer addition/removal
    const handleStyleLoad = () => {
      addCustomImageLayer();
    };

    // Listen for style load and handle initial layer
    map.on('style.load', handleStyleLoad);
    
    // Handle initial style or immediate layer setup
    if (!map.isStyleLoaded()) {
      handleStyleLoad();
    } else {
      addCustomImageLayer();
    }

    // Cleanup function
    return () => {
      map.off('style.load', handleStyleLoad);
        if (!isUnmountingRef.current) {
          const map = mapRef.current && mapRef.current.getMap();
        if (map && map.getLayer('custom-tiles-layer')) {
          map.removeLayer('custom-tiles-layer');
        }
        if (map.getSource('custom-tiles')) {
          map.removeSource('custom-tiles');
        }
      }
    };
  }, [isImageEnabled, projectId, imageName, imageBoundingBox, basemapOpacity, backendUrl, mapRef]);

  // Handle opacity changes
  useEffect(() => {
    const map = mapRef.current && mapRef.current.getMap();
    if (map && map.getLayer('custom-tiles-layer')) {
      map.setPaintProperty('custom-tiles-layer', 'raster-opacity', basemapOpacity);
    }
  }, [basemapOpacity, mapRef]);

  return null;
};

CustomImageLayer.propTypes = {
  projectId: PropTypes.string.isRequired,
  imageName: PropTypes.string,
  imageBoundingBox: PropTypes.arrayOf(PropTypes.number),
  isImageEnabled: PropTypes.bool.isRequired,
  basemapOpacity: PropTypes.number.isRequired,
  backendUrl: PropTypes.string.isRequired,
  mapRef: PropTypes.object.isRequired,
};

export default CustomImageLayer;

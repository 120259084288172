// src/features/projects/sgam/components/MapContainer.js

import React, { useRef, useState, useEffect, useMemo } from 'react';
import Map, { Popup, NavigationControl, Layer, Source} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import turfBbox from '@turf/bbox';

// Components
import LegendControl from './mapcomponents/LegendControl';
import MapControls from './mapcomponents/MapControls';
import PegmanControl from './mapcomponents/PegmanControl';
import StreetViewModal from './mapcomponents/StreetViewModal';
import CustomImageLayer from './mapcomponents/CustomImageLayer';

// Hooks
import useMapControls from '../hooks/useMapControls';
import useGeoJSONLayers from '../hooks/useGeoJSONLayers';
import useFeatureInteraction from '../hooks/useFeatureInteraction';
import usePegman from '../hooks/usePegman';
import useStreetCoverageLayer from '../hooks/useStreetCoverageLayer';
import useTerrainEffect from '../hooks/useTerrainEffect';
import useMapEventListeners from '../hooks/useMapEventListeners';

// Styles
import styles from '../styles/SgamPage.module.css';

// Buttons
import ZoomToProjectButton from './UI/ZoomToProjectButton';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;
const BACKEND_URL = API_URL.replace('/api', '');

// 3D Buildings Layer configuration
const buildingsLayer = {
  id: '3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 15,
  paint: {
    'fill-extrusion-color': '#aaa',
    'fill-extrusion-height': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'height'],
    ],
    'fill-extrusion-base': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'min_height'],
    ],
    'fill-extrusion-opacity': 0.8,
  },
};

const MapContainer = ({
  geojsonDataArray,
  selectedFeature,
  selectedBasemap,
  basemapOpacity,
  onBasemapChange,
  onOpacityChange,
  selectedStructures,
  projectId,
  imageName,
  imageBoundingBox,
  isImageEnabled,
}) => {
  const mapRef = useRef();
  const mapWrapperRef = useRef(null);
  const [viewport, setViewport] = useState({
    latitude: 41.9028,
    longitude: 12.4964,
    zoom: 9.3,
    bearing: 0,
    pitch: 0,
  });

  // Custom hooks
  const {
    isTerrainEnabled,
    isBuildingsEnabled,
    isSettingsOpen,
    selectedBasemap: currentBasemap,
    basemapOpacity: currentOpacity,
    toggleSettings,
    handleBasemapChange,
    handleOpacityChange,
    toggleTerrain,
    toggleBuildings,
  } = useMapControls(selectedBasemap, basemapOpacity);

  const {
    selectedPopup,
    hoveredFeatureId,
    handleFeatureClick,
    handleFeatureHover,
    closePopup,
    getPopupContent,
  } = useFeatureInteraction();

  const { geojsonSources, interactiveLayerIds } = useGeoJSONLayers({
    geojsonDataArray,
    selectedFeature,
    selectedStructures,
    hoveredFeatureId,
    basemapOpacity: currentOpacity,
    mapRef,
  });

  // Combine all GeoJSON features into one FeatureCollection for bounding
  const combinedGeojson = useMemo(() => {
    const allFeatures = geojsonDataArray.reduce((acc, item) => {
      if (item.geojson && item.geojson.features) {
        return acc.concat(item.geojson.features);
      }
      return acc;
    }, []);
    return {
      type: 'FeatureCollection',
      features: allFeatures,
    };
  }, [geojsonDataArray]);

  useEffect(() => {
    if (
      combinedGeojson &&
      combinedGeojson.features &&
      combinedGeojson.features.length > 0
    ) {
      const bbox = turfBbox(combinedGeojson);
      if (mapRef.current && mapRef.current.getMap()) {
        mapRef.current.getMap().fitBounds(
          [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]],
          ],
          { padding: 40, duration: 1000 }
        );
      }
    }
  }, [combinedGeojson]);

  // Street View functionality
  const [isStreetViewModalOpen, setIsStreetViewModalOpen] = useState(false);
  const [streetViewLocation, setStreetViewLocation] = useState(null);

  const handleStreetViewOpen = (location) => {
    setStreetViewLocation(location);
    setIsStreetViewModalOpen(true);
  };

  const {
    isPegmanDragging,
    pegmanScreenPosition,
    handlePegmanMouseDown,
  } = usePegman(mapRef, mapWrapperRef, handleStreetViewOpen);

  // Apply hooks
  useStreetCoverageLayer(mapRef, isPegmanDragging);
  useTerrainEffect(mapRef, isTerrainEnabled, setViewport);
  useMapEventListeners(mapRef, interactiveLayerIds);

  return (
    <div ref={mapWrapperRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Map
        {...viewport}
        style={{ width: '100%', height: '100%' }}
        mapStyle={currentBasemap.styleURL}
        mapboxAccessToken={MAPBOX_TOKEN}
        onMove={(evt) => setViewport(evt.viewState)}
        ref={mapRef}
        interactiveLayerIds={interactiveLayerIds}
        onClick={handleFeatureClick}
        attributionControl={true}
      >
        {/* Custom Image Layer */}
        <CustomImageLayer
          projectId={projectId}
          imageName={imageName}
          imageBoundingBox={imageBoundingBox}
          isImageEnabled={isImageEnabled}
          basemapOpacity={currentOpacity}
          backendUrl={BACKEND_URL}
          mapRef={mapRef}
        />
        
        {/* GeoJSON Sources and Layers */}
        {geojsonSources.map((source) => (
          <Source
            key={source.id}
            id={source.id}
            type="geojson"
            data={source.data}
            maxzoom={24}
            tolerance={0}
          >
            {source.layers.map((layer) => (
              <Layer key={layer.id} {...layer} />
            ))}
          </Source>
        ))}

        {/* 3D Buildings Layer */}
        {isBuildingsEnabled && <Layer {...buildingsLayer} />}

        {/* Navigation Controls and Custom Zoom Button */}
        <div className="mapbox-controls" style={{ position: 'absolute', left: 8, bottom: 130 }}>
          <NavigationControl position="bottom-left" />
          <ZoomToProjectButton mapRef={mapRef} geojsonDataArray={geojsonDataArray} />
        </div>

        {/* Popup */}
        {selectedPopup && (
          <Popup
            latitude={selectedPopup.latitude}
            longitude={selectedPopup.longitude}
            onClose={closePopup}
            closeOnClick={false}
          >
            {getPopupContent()}
          </Popup>
        )}
      </Map>

      {/* Map Controls */}
      <MapControls
        isTerrainEnabled={isTerrainEnabled}
        isBuildingsEnabled={isBuildingsEnabled}
        isSettingsOpen={isSettingsOpen}
        selectedBasemap={currentBasemap}
        basemapOpacity={currentOpacity}
        onTerrainToggle={toggleTerrain}
        onBuildingsToggle={toggleBuildings}
        onBasemapChange={handleBasemapChange}
        onOpacityChange={handleOpacityChange}
        onSettingsToggle={toggleSettings}
      />

      {/* Pegman Control */}
      <PegmanControl
        isPegmanDragging={isPegmanDragging}
        pegmanScreenPosition={pegmanScreenPosition}
        handlePegmanMouseDown={handlePegmanMouseDown}
        mapWrapperRef={mapWrapperRef}
        styles={styles}
      />

      {/* Legend */}
      <div className={styles.legend}>
        <LegendControl featureKey={selectedFeature} />
      </div>

      {/* Street View Modal */}
      {isStreetViewModalOpen && streetViewLocation && (
        <StreetViewModal
          streetViewLocation={streetViewLocation}
          onClose={() => setIsStreetViewModalOpen(false)}
          GOOGLE_MAPS_API_KEY={GOOGLE_MAPS_API_KEY}
          styles={styles}
        />
      )}
    </div>
  );
};

MapContainer.propTypes = {
  geojsonDataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFeature: PropTypes.string.isRequired,
  selectedBasemap: PropTypes.object.isRequired,
  basemapOpacity: PropTypes.number.isRequired,
  onBasemapChange: PropTypes.func.isRequired,
  onOpacityChange: PropTypes.func.isRequired,
  selectedStructures: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectId: PropTypes.string.isRequired,
  imageName: PropTypes.string,
  imageBoundingBox: PropTypes.arrayOf(PropTypes.number),
  isImageEnabled: PropTypes.bool.isRequired,
};

export default MapContainer;

// src/utils/mapUtils.js

import React, { useState } from 'react';
import '../styles/Popup.css';
import { ReactComponent as InfoIcon} from '../styles/symbols/info-circle-svgrepo-com.svg';
import { ReactComponent as LandslideIcon} from '../styles/symbols/landslide-svgrepo-com.svg';
import { ReactComponent as LocationIcon} from '../styles/symbols/location-pin-svgrepo-com.svg';
import { ReactComponent as SubsidenceIcon} from '../styles/symbols/subsidence-svgrepo-com.svg';
import Liquefaction from '../styles/symbols/liquefaction.png';
import { ReactComponent as WarningIcon} from '../styles/symbols/warning-svgrepo-com.svg';
import ProjectInfoPopup from '../components/popups/ProjectInfoPopup';

export const getColor = (value) => {
    const lowercaseValue = String(value).toLowerCase();
    switch (lowercaseValue) {
        case 'low':
            return '#1a9850';  // Green
        case 'med-low':
        case 'medium-low':
            return '#a3b61d';  // Yellow-green
        case 'medium':
            return '#ffe41a';  // Yellow
        case 'med-high':
        case 'medium-high':
            return '#f0801a';  // Orange
        case 'high':
            return '#d73027';  // Red
        case 'road':
            return '#808080';  // Gray
        case 'bridge':
            return '#4682b4';  // Steel blue
        case 'tunnel':
            return '#2F2F2F';  // Charcoal (earthy/underground)
        default:
            return 'transparent';  // Default
    }
};

export const formatPropertyName = (name) => {
    // Special case for landslide_rockfall
    if (name === 'landslide_rockfall_hazard') {
        return 'Rockfall';
    }

    return name
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .replace('Hazard', '')
        .trim();
};

export const getTextColor = (bgColor) => {
    if (bgColor === 'transparent') {
        return '#000000';
    }
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1) : bgColor;
    const bigint = parseInt(color, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.7 ? '#000000' : '#FFFFFF';
};

const HazardLevel = ({ value }) => {
    const bgColor = getColor(value);
    const textColor = getTextColor(bgColor);
    
    return (
        <span
            className="hazard-level"
            style={{
                backgroundColor: bgColor,
                color: textColor,
            }}
        >
            {typeof value === 'number' ? value.toFixed(5) : String(value).toUpperCase()}
        </span>
    );
};

const generateHazardSection = (properties) => {
    const excludedKeys = ['name', 'description', 'structure', 'summary', 'latitude', 'longitude'];
    const hazardKeys = Object.keys(properties).filter(key => !excludedKeys.includes(key));

    if (hazardKeys.length === 0) return null;

    return (
        <div className="popup-hazards">
            <h4>{properties['structure'] || 'SGAM Feature'} A15</h4>
            
            <LocationIcon width={20} height={20} className="location-icon" />
              <span className="coordinates">
            Lat:{properties['latitude'].toFixed(2)}; Lon:{properties['longitude'].toFixed(2)}
            </span> <br />
            
            <div className="hazard-item">
                <WarningIcon width={20} height={20} className="warning-icon" />
                <div className="mainrisk">Overall Risk:</div>
                    {properties['summary'] && ( 
                    <HazardLevel value={properties['summary']}></HazardLevel>
                    )}
            </div>
            
            <div className="divider">------------------------------------</div>
            
            <ul>
                {hazardKeys.map((key, index) => {
                    const formattedName = formatPropertyName(key);
                    return (
                        <div key={index} className = 'hazard-item'>
                                {formattedName === 'Subsidence' ? (
                                    <SubsidenceIcon width={20} height={20} className="subsidence-icon" />
                                ) : formattedName === 'Liquefaction' ?(
                                    <img src={Liquefaction} width={20} height={20} className="liquefaction-icon" />
                                ) :  (
                                    <LandslideIcon width={20} height={20} className="landslide-icon" />
                                )}
                            <div className="mainrisk">
                                <span>{formattedName}:</span></div>
                                <HazardLevel value={properties[key]} />
                        </div>
                    );
                })}
            </ul>
        </div>
    );
};

const PopupContent = ({ properties }) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <div className="popup-container">
            <div className="popup-header">
                <h3>
                    Hazard Levels
                    <button 
                        className="info-button"
                        onClick={() => setShowInfo(true)}
                        aria-label="Show project information"
                    >
                        <InfoIcon width={10} height={10} className="info-icon" />
                    </button>
                </h3>
            </div>
            {properties.description && (
                <div className="popup-description">
                    <p>{properties.description}</p>
                </div>
            )}
            {generateHazardSection(properties)}
            {showInfo && <ProjectInfoPopup onClose={() => setShowInfo(false)} />}
        </div>
    );
};

export const generatePopupContent = (properties) => {
    return <PopupContent properties={properties} />;
};

// src/components/ServiceSelection.js
import React, { useEffect, useState, useContext } from 'react';
import api from '../../../api';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/context/AuthContext';
import styles from '../styles/ServiceSelection.module.css';
import { Link } from 'react-router-dom'; // Ensure Link is imported

const ServiceSelection = () => {
    const { serviceId } = useParams();
    const [projects, setProjects] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchServiceAndProjects = async () => {
            try {
                // Fetch all services to find the current service name
                const serviceRes = await api.get(`/services`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });
                const service = serviceRes.data.find(s => s._id === serviceId);
                if (!service) {
                    setError('Service not found.');
                    setLoading(false);
                    return;
                }
                setServiceName(service.name);

                // Fetch projects for the selected service
                const projectsRes = await api.get(`/projects/service/${serviceId}`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });
                setProjects(projectsRes.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to load projects.');
                setLoading(false);
            }
        };
        fetchServiceAndProjects();
    }, [serviceId, auth.token]);

    const handleBack = () => {
        navigate(-1);
    };

    if (loading) {
        return <div className={styles.container}><p className={styles.loading}>Loading projects...</p></div>;
    }

    if (error) {
        return <div className={styles.container}><p className={styles.error}>{error}</p></div>;
    }

    return (
        <div className={styles.container}>
            {/* Header Section */}
            <div className={styles.header}>
                <h2 className={styles.title}>Projects for {serviceName}</h2>
                <button onClick={handleBack} className={styles.backButton} aria-label="Back to Dashboard">Back</button>
            </div>

            {/* Admin Link Section (Optional, if applicable) */}
            {auth.user && auth.user.role === 'admin' && (
                <div className={styles.adminLinkContainer}>
                    <Link to="/admin" className={styles.adminLink}>
                        Go to Admin Dashboard
                    </Link>
                </div>
            )}

            {/* Projects Selection */}
            <div className={styles.projects}>
                {projects.map(project => (
                    <button 
                        key={project._id} 
                        onClick={() => navigate(`/projects/${project._id}`)}
                        className={styles.projectButton}
                    >
                        {project.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ServiceSelection;

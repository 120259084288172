// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './features/auth/components/Login';
import Register from './features/auth/components/Register';
import ForgotPassword from './features/auth/components/ForgotPassword';
import ResetPassword from './features/auth/components/ResetPassword';
import Dashboard from './features/dashboard/components/Dashboard';
import ServiceSelection from './features/dashboard/components/ServiceSelection';
import ProjectDetail from './features/projects/common/components/ProjectDetail';
import ProtectedRoute from './features/projects/common/components/ProtectedRoute';
import AdminDashboard from './features/dashboard/components/AdminDashboard';
import DemoDashboard from './features/dashboard/components/DemoDashboard';
import Layout from './features/dashboard/components/Layout';
import { AuthProvider } from './features/auth/context/AuthContext';

// AdminRoute ensures only admins can access /admin/*
import { useContext } from 'react';
import { AuthContext } from './features/auth/context/AuthContext';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
    const { auth } = useContext(AuthContext);
    return auth.user && auth.user.role === 'admin' ? children : <Navigate to="/" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes: Login, Register, and Password Reset */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* Project Detail Route: Excluded from Layout */}
          <Route 
            path="/projects/:projectId" 
            element={
              <ProtectedRoute>
                <ProjectDetail />
              </ProtectedRoute>
            } 
          />

          {/* Protected Routes Wrapped Inside Layout */}
          <Route path="/" element={<Layout />}>
            {/* Dashboard */}
            <Route 
              index 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />

            {/* Admin Dashboard */}
            <Route 
              path="admin/*" 
              element={
                <ProtectedRoute>
                  <AdminRoute>
                    <AdminDashboard />
                  </AdminRoute>
                </ProtectedRoute>
              } 
            />

            <Route
              path="demo/"
              index 
              element={
                <ProtectedRoute>
                  <DemoDashboard />
                </ProtectedRoute>
              }
            />

            {/* Service Selection */}
            <Route 
              path="services/:serviceId" 
              element={
                <ProtectedRoute>
                  <ServiceSelection />
                </ProtectedRoute>
              } 
            />

            {/* 404 Not Found Route */}
            <Route path="*" element={<h1>404: Page Not Found</h1>} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

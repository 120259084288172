import { useState, useCallback } from 'react';
import { generatePopupContent } from '../utils/mapUtils';

export const useFeatureInteraction = () => {
  const [selectedPopup, setSelectedPopup] = useState(null);
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);

  const handleFeatureClick = useCallback((event) => {
    const feature = event.features?.[0];
    if (feature) {
      setSelectedPopup({
        ...feature.properties,
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng,
      });
    }
  }, []);

  const handleFeatureHover = useCallback((featureId) => {
    setHoveredFeatureId(featureId);
  }, []);

  const closePopup = useCallback(() => {
    setSelectedPopup(null);
  }, []);

  const getPopupContent = useCallback(() => {
    if (!selectedPopup) return null;
    return generatePopupContent(selectedPopup);
  }, [selectedPopup]);

  return {
    // State
    selectedPopup,
    hoveredFeatureId,

    // Actions
    handleFeatureClick,
    handleFeatureHover,
    closePopup,
    getPopupContent,
  };
};

export default useFeatureInteraction;

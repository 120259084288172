// src/components/LandslideProjectDetail/Popup.js

import React from 'react';
import { Rnd } from 'react-rnd';
import styles from '../../common/styles/ProjectDetail.module.css';
import TimeseriesChart from './TimeseriesChart';
import PropTypes from 'prop-types';

const Popup = ({ popup, removePopup }) => {
    const yDomain = [-15, 15]; // Fixed y-axis range

    return (
        <Rnd
            default={{
                x: popup.position.x,
                y: popup.position.y,
                width: 400,
                height: 300,
            }}
            bounds="window"
            minWidth={300}
            minHeight={220}
            className={styles.draggablePopup}
            dragHandleClassName={styles.dragHandle} // Specify the drag handle
        >
            <div className={styles.popupContent}>
                <div className={styles.popupHeader}>
                    {/* Drag Handle: Only the title is draggable */}
                    <div className={styles.dragHandle}>
                        {popup.type === 'single' ? (
                            <h3>
                                Timeseries for Point ({popup.x.toFixed(0)}, {popup.y.toFixed(0)})
                            </h3>
                        ) : (
                            <h3 style={{ color: popup.color }}>Average Timeseries</h3>
                        )}
                    </div>
                    {/* Close Button: Positioned outside the drag handle */}
                    <button
                        onClick={(e) => {
                            e.stopPropagation(); // Prevents drag events from triggering
                            removePopup(popup.id);
                        }}
                        className={styles.closeButton}
                        aria-label="Close Popup"
                    >
                        ×
                    </button>
                </div>
                <div className={styles.popupChart}>
                    {popup.loading ? (
                        <div className={styles.spinner}>
                            <div className={styles.doubleBounce1}></div>
                            <div className={styles.doubleBounce2}></div>
                        </div>
                    ) : (
                        popup.type === 'single' ? (
                            popup.timeseries.length > 0 ? (
                                <TimeseriesChart
                                    data={popup.timeseries}
                                    type="single"
                                    yDomain={yDomain} // Pass yDomain here
                                />
                            ) : (
                                <p>No timeseries data available for this point.</p>
                            )
                        ) : (
                            popup.averageTimeseries.length > 0 ? (
                                <TimeseriesChart
                                    data={popup.averageTimeseries}
                                    type="multi"
                                    color={popup.color}
                                    yDomain={yDomain} // Pass yDomain here
                                />
                            ) : (
                                <p>No timeseries data available for this group.</p>
                            )
                        )
                    )}
                </div>
            </div>
        </Rnd>
    );
};

// Define PropTypes for better type checking
Popup.propTypes = {
    popup: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['single', 'multi']).isRequired,
        position: PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
        }).isRequired,
        x: PropTypes.number, // For 'single' type
        y: PropTypes.number, // For 'single' type
        color: PropTypes.string, // For 'multi' type
        loading: PropTypes.bool.isRequired,
        timeseries: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.instanceOf(Date),
                    PropTypes.number
                ]).isRequired,
                displacement: PropTypes.number.isRequired,
            })
        ),
        averageTimeseries: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.instanceOf(Date),
                    PropTypes.number
                ]).isRequired,
                displacement: PropTypes.number.isRequired,
            })
        ),
    }).isRequired,
    removePopup: PropTypes.func.isRequired,
};

export default Popup;

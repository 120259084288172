// src/components/LandslideProjectDetail/ColorBar.js

import React from 'react';
import styles from '../../common/styles/ProjectDetail.module.css';

const ColorBar = ({ displacementType, minDisplacement, maxDisplacement, colorScale }) => {
    // Generate intermediate color stops for the gradient
    const numStops = 100; // Number of steps for smooth gradient
    const stops = Array.from({ length: numStops }, (_, i) =>
        minDisplacement + (i / (numStops - 1)) * (maxDisplacement - minDisplacement)
    );

    // Map stops to corresponding colors
    const gradientColors = stops.map(value => colorScale(value));

    // Create a CSS gradient string
    const gradientStyle = {
        background: `linear-gradient(to right, ${gradientColors.join(', ')})`,
        height: '20px',
        width: '200px',
        position: 'relative',
        border: '1px solid #000',
    };

    return (
        <div className={styles.colorBarContainer}>
            <div className={styles.colorBarTitle}>
                {/* S{displacementType === 'EW' ? 'Horizontal' : 'Vertical'}  */}
                Displacement [mm]
            </div>
            <div className={styles.colorBarGradient} style={gradientStyle} />
            <div className={styles.colorBarLabels}>
                <span>{minDisplacement.toFixed(2)}</span>
                <span>{maxDisplacement.toFixed(2)}</span>
            </div>
        </div>
    );
};

export default ColorBar;

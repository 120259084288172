// src/landslide/components/CanvasLayer.js

import React from 'react';
import styles from '../../common/styles/ProjectDetail.module.css';

const CanvasLayer = ({
    staticCanvasRef,
    highlightCanvasRef,
    onClick,
    onDoubleClick,
    onMouseMove
}) => {
    return (
        <>
            <canvas 
                ref={staticCanvasRef} 
                className={styles.staticCanvas}
            />
            <canvas 
                ref={highlightCanvasRef} 
                className={styles.highlightCanvas}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                onMouseMove={onMouseMove}
            />
        </>
    );
};

export default CanvasLayer;

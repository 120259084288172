// src/features/projects/sgam/components/SgamProjectDetail.js

import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../features/auth/context/AuthContext';
import { FaList } from 'react-icons/fa';
import styles from '../styles/SgamPage.module.css';
import '../../../../styles/global.css';
import '../styles/Popup.css';
import { getSGAMGeoJSON, getSGAMGeoreferencedImages } from '../utils/sgamService';
import { basemapOptions } from '../utils/basemaps';
import MapContainer from './MapContainer';
import BackButton from './UI/BackButton';
import LoadingSpinner from './LoadingSpinner';
import FeatureSelectionPopup from './mapcomponents/FeatureSelectionPopup';
import useClickOutside from '../hooks/useClickOutside';

const DEFAULT_STRUCTURES = ['road', 'bridge', 'tunnel'];
const DEFAULT_BASEMAP = basemapOptions[0];
const DEFAULT_OPACITY = 1;

const SgamProjectDetail = ({ project }) => {
  const { _id: projectId } = project;
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  // State management
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [geojsonDataArray, setGeojsonDataArray] = useState([]);
  const [uploadDate, setUploadDate] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState('summary');
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [selectedBasemap, setSelectedBasemap] = useState(DEFAULT_BASEMAP);
  const [basemapOpacity, setBasemapOpacity] = useState(DEFAULT_OPACITY);
  const [isFeaturePopupOpen, setIsFeaturePopupOpen] = useState(false);
  const [selectedStructures, setSelectedStructures] = useState(DEFAULT_STRUCTURES);

  const featureRef = React.useRef(null);

  // Handlers
  const toggleStructure = useCallback((structure) => {
    setSelectedStructures(prev => 
      prev.includes(structure)
        ? prev.filter(s => s !== structure)
        : [...prev, structure]
    );
  }, []);
  const [georeferencedImages, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // To store the currently selected image
  const [imageBoundingBox, setImageBoundingBox] = useState(null);
  const [isImageEnabled, setIsImageEnabled] = useState(true); // New state for image rendering


  // Fetch GeoJSON data
  useEffect(() => {
    if (!projectId) {
      setError('No project ID provided.');
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const geojsonFiles = await 
        getSGAMGeoJSON(projectId);
        setGeojsonDataArray(geojsonFiles.geojsonFiles);
        setUploadDate(geojsonFiles.length > 0 ? geojsonFiles[0].uploadedAt : null);
        
        const features = extractAvailableFeatures(geojsonFiles);
        setAvailableFeatures(features);
        setSelectedFeature(features.includes('summary') ? 'summary' : features[0] || '');

        // Fetch Georeferenced Images
        const images = await getSGAMGeoreferencedImages(projectId);
        setImages(images);

        // Select the first image by default
        if (images.length > 0) {
          const firstImage = images[0];
          setSelectedImage(firstImage);
          setImageBoundingBox(firstImage.boundingBox);
        }
      } catch (err) {
        console.error('Error fetching SGAM data:', err);
        setError('Failed to fetch project data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

   // Extract available features from GeoJSON
   const extractAvailableFeatures = (geojsonArray) => {
    if (!geojsonArray?.geojsonFiles?.length) return [];
  
    const featureSet = new Set();
  
    geojsonArray.geojsonFiles.forEach(({ geojson }) => {
      if (geojson?.features?.length) {
        geojson.features.forEach((feature) => {
          if (feature.properties) {
            Object.keys(feature.properties)
              .filter(key => !['name', 'description'].includes(key))
              .forEach(key => featureSet.add(key));
          }
        });
      }
    });
    return Array.from(featureSet);
   };

  const handleFeatureChange = (selected) => {
    setSelectedFeature(selected);
  };

  const handleBasemapChange = (selectedBasemap) => {
    setSelectedBasemap(selectedBasemap);
  };

  const handleOpacityChange = (opacity) => {
    setBasemapOpacity(opacity);
  };

  const toggleFeaturePopup = () => {
    setIsFeaturePopupOpen((prev) => !prev);
  };

  // Close feature popup when clicking outside
  useClickOutside(featureRef, () => {
    if (isFeaturePopupOpen) setIsFeaturePopupOpen(false);
  });

  const handleImageSelection = (image) => {
    setSelectedImage(image);
    setImageBoundingBox(image.boundingBox); 
  };

  const handleImageToggle = (enabled) => {
    setIsImageEnabled(enabled);
  };

  return (
    <div className={styles.page}>
      {loading && (
        <div className={styles.loadingOverlay}>
          <LoadingSpinner />
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.mapContainer}>
            <div className={styles.textContentOverlay}>
              <h1>
                <img
                  src="/sgam.ico"
                  alt="Project Icon"
                  className={styles.projectIcon}
                  // style={{ width: '50px', height: '50px', marginLeft: '5px', marginRight: '5px', marginTop: '5px' }}
                />
                {project.name}
              </h1>
              {!geojsonDataArray.length && (
                <p>No GeoJSON data available for this project.</p>
              )}
            </div>

            <div className={styles.mapControls}>
              <div className={styles.featureButtonContainer} ref={featureRef}>
                <button
                  className={styles.featureButton}
                  onClick={toggleFeaturePopup}
                  aria-label="Feature Selection"
                >
                  <FaList />
                </button>
                {isFeaturePopupOpen && (
                  <div className={styles.popup}>
                    <FeatureSelectionPopup
                      availableFeatures={availableFeatures}
                      selectedFeature={selectedFeature}
                      onFeatureChange={handleFeatureChange}
                      selectedStructures={selectedStructures} // Pass selectedStructures
                      toggleStructure={toggleStructure}       // Pass toggleStructure handler
                      georeferencedImages={georeferencedImages}
                      selectedImage={selectedImage}
                      onImageChange={handleImageSelection}
                      isImageEnabled={isImageEnabled} // Passing new prop
                      onImageToggle={handleImageToggle} // Passing new handler
                    />
                  </div>
                )}
              </div>
              <BackButton />
            </div>

            {/* Map Container */}
            <MapContainer
              geojsonDataArray={geojsonDataArray}
              selectedFeature={selectedFeature}
              onFeatureChange={handleFeatureChange}
              availableFeatures={availableFeatures}
              selectedBasemap={selectedBasemap}
              basemapOpacity={basemapOpacity}
              onBasemapChange={handleBasemapChange}
              onOpacityChange={handleOpacityChange}
              selectedStructures={selectedStructures}
              projectId={projectId}
              imageName={selectedImage ? selectedImage.name : null}
              imageBoundingBox={imageBoundingBox}
              isImageEnabled={isImageEnabled} // Passing new prop              
              />
          </div>        
        </div>
      </div>
    </div>
  );
};

export default React.memo(SgamProjectDetail);

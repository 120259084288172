// src/components/TunnelProjectDetail/hooks/useTunnelCycle.js

import { useState, useEffect } from 'react';

const useTunnelCycle = (images) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedImage, setDisplayedImage] = useState(null);

  useEffect(() => {
    if (images.length > 0) {
      setCurrentIndex(0);
      setDisplayedImage(images[0]);
    } else {
      setDisplayedImage(null);
    }
  }, [images]);

  useEffect(() => {
    if (images.length > 0 && currentIndex >= 0 && currentIndex < images.length) {
      setDisplayedImage(images[currentIndex]);
    } else {
      setDisplayedImage(null);
    }
  }, [currentIndex, images]);

  return {
    currentIndex,
    setCurrentIndex,
    displayedImage,
  };
};

export default useTunnelCycle;

// src/components/LandslideProjectDetail/hooks/useSelectionMode.js

import { useState, useRef, useCallback } from 'react';

const useSelectionMode = () => {
    const [selectionMode, setSelectionMode] = useState('Disabled'); // 'Disabled' | 'Point' | 'Polygon'
    const [polygonPoints, setPolygonPoints] = useState([]);
    const [currentMousePos, setCurrentMousePos] = useState(null);
    const isDrawing = useRef(false);
    const isFinalizing = useRef(false);

    const toggleSelectionMode = useCallback(() => {
        if (selectionMode === 'Disabled') {
            setSelectionMode('Point');
        } else if (selectionMode === 'Point') {
            setSelectionMode('Polygon');
        } else {
            setSelectionMode('Disabled');
            setPolygonPoints([]);
            setCurrentMousePos(null);
            isDrawing.current = false;
            isFinalizing.current = false;
        }
    }, [selectionMode]);

    const addPolygonPoint = useCallback((point) => {
        setPolygonPoints(prevPoints => [...prevPoints, point]);
    }, []);

    const resetSelection = useCallback(() => {
        setSelectionMode('Disabled');
        setPolygonPoints([]);
        setCurrentMousePos(null);
        isDrawing.current = false;
        isFinalizing.current = false;
    }, []);

    return {
        selectionMode,
        toggleSelectionMode,
        polygonPoints,
        setPolygonPoints,
        currentMousePos,
        setCurrentMousePos,
        isDrawing,
        isFinalizing,
        addPolygonPoint,
        resetSelection
    };
};

export default useSelectionMode;

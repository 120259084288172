// src/components/ErrorBoundary.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/ErrorBoundary.module.css'; // Optional: For styling the fallback UI

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  // Update state when an error is encountered
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  // Log the error details (could be sent to an external logging service)
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    console.error('ErrorBoundary caught an error', error, errorInfo);
    // You can also log the error to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      // You can customize the fallback UI here
      return (
        <div className={styles.errorBoundary}>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
          {this.props.fallback && <this.props.fallback />}
          {/* Optionally, you can add a button to reset the error boundary */}
          {this.props.onReset && (
            <button onClick={this.props.onReset}>Try Again</button>
          )}
        </div>
      );
    }

    // Render children if no error
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.elementType, // Optional fallback component
  onReset: PropTypes.func, // Optional reset handler
};

ErrorBoundary.defaultProps = {
  fallback: null,
  onReset: null,
};

export default ErrorBoundary;

// src/components/RockfallProjectDetail/hooks/useRockfallImages.js

import { useState, useEffect } from 'react';
import api from '../../../../api';

const useRockfallImages = (projectId, authToken) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [imageGroups, setImageGroups] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      setError('');
      try {
        const params = {
          includeSingleTimestamp: 'true',
        };

        const imagesRes = await api.get(
          `/projects/project/${projectId}/images`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
            params: params,
          }
        );

        let fetchedImages = imagesRes.data;

        // Sort by toTimestamp ascending
        fetchedImages.sort(
          (a, b) => new Date(a.toTimestamp) - new Date(b.toTimestamp)
        );

        // Separate two-timestamp and single timestamp images
        const twoTimestamp = fetchedImages.filter(
          (img) => img.fromTimestamp && img.toTimestamp
        );
        const singleTimestamp = fetchedImages.filter(
          (img) => img.timestamp && !img.fromTimestamp && !img.toTimestamp
        );

        // Map two-timestamp images to their single timestamp counterparts
        const groups = twoTimestamp
          .map((twoImg) => {
            const fromSingle = singleTimestamp.find((singleImg) => {
              return (
                new Date(singleImg.timestamp).getTime() ===
                new Date(twoImg.fromTimestamp).getTime()
              );
            });
            const toSingle = singleTimestamp.find((singleImg) => {
              return (
                new Date(singleImg.timestamp).getTime() ===
                new Date(twoImg.toTimestamp).getTime()
              );
            });
            console.log(twoImg)
            return {
              twoImg,
              fromSingle,
              toSingle,
            };
          })
          .filter((group) => group.twoImg); // Ensure twoImg exists

        setImageGroups(groups);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('Failed to load images.');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [projectId, authToken]);

  return { loading, error, imageGroups };
};

export default useRockfallImages;

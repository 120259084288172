// src/features/projects/sgam/components/mapcomponents/FeatureSelectionPopup.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/FeatureSelectionPopup.module.css'; // Ensure appropriate CSS for popups

const FeatureSelectionPopup = ({ 
  availableFeatures, 
  selectedFeature, 
  onFeatureChange,
  selectedStructures,      // Receive selectedStructures
  toggleStructure,          // Receive toggleStructure handler
  georeferencedImages,
  selectedImage,
  onImageChange,
  isImageEnabled, // New prop
  onImageToggle  // New prop
}) => {
  return (
    <div className={styles.popupContent}>
      {/* Feature Selection */}
      <h3>Select Feature</h3>
      <select 
        value={selectedFeature} 
        onChange={(e) => onFeatureChange(e.target.value)}
        className={styles.select}
      >
        {availableFeatures.map((feature) => (
          <option key={feature} value={feature}>
            {feature.charAt(0).toUpperCase() + feature.slice(1).replace(/_/g, ' ')} 
          </option>
        ))}
      </select>

      {/* **Add Structure Filters Below the Dropdown** */}
      <div className={styles.structureFilters}>
        <h4>Filter Structures</h4>
        <label className={styles.toggleLabel}>
          <div className={`${styles.switch} ${styles.smallSwitch}`}>
            <input
              type="checkbox"
              checked={selectedStructures.includes('road')}
              onChange={() => toggleStructure('road')}
            />
            <span className={styles.slider}></span>
          </div>
          <span className={styles.toggleLabelText}>
            Road
          </span>
        </label>
        <label className={styles.toggleLabel}>
          <div className={`${styles.switch} ${styles.smallSwitch}`}>
            <input
              type="checkbox"
              checked={selectedStructures.includes('bridge')}
              onChange={() => toggleStructure('bridge')}
            />
            <span className={styles.slider}></span>
          </div>
          <span className={styles.toggleLabelText}>
            Bridge
          </span>
        </label>
        <label className={styles.toggleLabel}>
          <div className={`${styles.switch} ${styles.smallSwitch}`}>
            <input
              type="checkbox"
              checked={selectedStructures.includes('tunnel')}
              onChange={() => toggleStructure('tunnel')}
            />
            <span className={styles.slider}></span>
          </div>
          <span className={styles.toggleLabelText}>
            Tunnel
          </span>
        </label>
      </div>

      {/* Image Selection Dropdown */}
      {georeferencedImages && georeferencedImages.length > 0 && (
        <div className={styles.imageSelector}>
          <h3>Select Image</h3>
          <select
            value={selectedImage ? selectedImage.name : ''}
            onChange={(e) => {
              const selected = georeferencedImages.find(img => img.name === e.target.value);
              onImageChange(selected);
            }}
            className={styles.select}
          >
            {georeferencedImages.map((image) => (
              <option key={image._id} value={image.name}>
                {image.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Checkbox to Enable/Disable Image Rendering */}
      {georeferencedImages && georeferencedImages.length > 0 && (
        <div className={styles.imageToggle}>
          <label className={styles.toggleLabel}>
            <div className={styles.switch}>
              <input
                type="checkbox"
                checked={isImageEnabled}
                onChange={(e) => onImageToggle(e.target.checked)}
              />
              <span className={styles.slider}></span>
            </div>
            <span className={styles.toggleLabelText}>
              Enable Image Rendering
            </span>
          </label>
        </div>
      )}
    </div>
  );
};

FeatureSelectionPopup.propTypes = {
  availableFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedFeature: PropTypes.string.isRequired,
  onFeatureChange: PropTypes.func.isRequired,
  selectedStructures: PropTypes.arrayOf(PropTypes.string).isRequired, // Define propTypes
  toggleStructure: PropTypes.func.isRequired,                        // Define propTypes
  georeferencedImages: PropTypes.arrayOf(PropTypes.object),
  selectedImage: PropTypes.object,
  onImageChange: PropTypes.func,
  isImageEnabled: PropTypes.bool.isRequired, // New prop type
  onImageToggle: PropTypes.func.isRequired,  // New prop type
};

export default FeatureSelectionPopup;

// src/components/Register.js
import React, { useState } from 'react';
import api from '../../../api';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../styles/Register.module.css';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const Register = () => {
    const [form, setForm] = useState({ email: '', password: '', confirmPassword: '' });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const navigate = useNavigate();

    // Password validation states
    const [validations, setValidations] = useState({
        validEmail: false,
        minLength: false,
        hasNumber: false,
        hasSpecial: false,
        passwordsMatch: false
    });

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        setValidations(prev => ({
            ...prev,
            minLength: password.length >= 8,
            hasNumber: /\d/.test(password),
            hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            passwordsMatch: password === form.confirmPassword
        }));
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
        
        if (name === 'email') {
            setValidations(prev => ({
                ...prev,
                validEmail: validateEmail(value)
            }));
        }
        if (name === 'password') {
            validatePassword(value);
        }
        if (name === 'confirmPassword') {
            setValidations(prev => ({
                ...prev,
                passwordsMatch: value === form.password
            }));
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validations.validEmail) {
            setError('Please enter a valid email address.');
            return;
        }
        if (!validations.minLength || !validations.hasNumber || !validations.hasSpecial || !validations.passwordsMatch) {
            setError('Please ensure all password requirements are met.');
            return;
        }

        setLoading(true);
        setError('');
        setSuccess('');

        try {
            await api.post('/auth/register', {
                email: form.email,
                password: form.password
            });
            
            // // Send verification email
            // await api.post('/auth/send-verification', {
            //     email: form.email
            // });
            
            setVerificationSent(true);
            setSuccess('Registration successful! Please check your email for verification link.');
            setForm({ email: '', password: '', confirmPassword: '' });
            
            // Don't navigate immediately, let user verify email first
        } catch(err) {
            setError(err.response?.data?.message || 'Registration failed');
        } finally {
            setLoading(false);
        }
    };

    // If user clicks verification link, they'll be redirected to /verify-email?token=xyz
    const verifyEmail = async (token) => {
        try {
            await api.post('/auth/verify-email', { token });
            setSuccess('Email verified successfully! You can now login.');
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (err) {
            setError('Email verification failed. Please try again.');
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Create Account</h2>
            {error && <p className={styles.errorMessage}>{error}</p>}
            {success && <p className={styles.successMessage}>{success}</p>}
            
            {verificationSent ? (
                <div className={styles.verificationMessage}>
                    <FaEnvelope className={styles.verificationIcon} />
                    <h3>Verify Your Email</h3>
                    <p>We've sent a verification link to <strong>{form.email}</strong></p>
                    <p>Please check your email and click the verification link to complete your registration.</p>
                    <p className={styles.resendLink}>
                        Didn't receive the email? <button 
                            onClick={() => handleSubmit} 
                            className={styles.resendButton}
                            disabled={loading}
                        >
                            Resend verification email
                        </button>
                    </p>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Email Address</label>
                        <div className={styles.inputWrapper}>
                            <FaEnvelope className={styles.inputIcon} />
                            <input 
                                type="email" 
                                name="email" 
                                id="email"
                                placeholder="Enter your email address" 
                                value={form.email}
                                onChange={handleChange}
                                required 
                                className={`${styles.input} ${
                                    form.email && (validations.validEmail ? styles.validInput : styles.invalidInput)
                                }`}
                                disabled={loading}
                            />
                            {form.email && (
                                <span className={styles.validationIcon}>
                                    {validations.validEmail ? 
                                        <FaCheckCircle className={styles.validIcon} /> : 
                                        <FaTimesCircle className={styles.invalidIcon} />
                                    }
                                </span>
                            )}
                        </div>
                    </div>

                    <div className={styles.formGroup}>
                        <label htmlFor="password">Password</label>
                        <div className={styles.inputWrapper}>
                            <FaLock className={styles.inputIcon} />
                            <input 
                                type={showPassword ? "text" : "password"}
                                name="password" 
                                id="password"
                                placeholder="Create a strong password" 
                                value={form.password}
                                onChange={handleChange}
                                required 
                                className={styles.input}
                                disabled={loading}
                            />
                            <button 
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className={styles.showPasswordButton}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>

                    <div className={styles.formGroup}>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <div className={styles.inputWrapper}>
                            <FaLock className={styles.inputIcon} />
                            <input 
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword" 
                                id="confirmPassword"
                                placeholder="Confirm your password" 
                                value={form.confirmPassword}
                                onChange={handleChange}
                                required 
                                className={styles.input}
                                disabled={loading}
                            />
                            <button 
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className={styles.showPasswordButton}
                            >
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>

                    <div className={styles.passwordRequirements}>
                        <h3>Requirements:</h3>
                        <ul>
                            <li className={validations.validEmail ? styles.valid : styles.invalid}>
                                {validations.validEmail ? <FaCheckCircle /> : <FaTimesCircle />}
                                Valid email address
                            </li>
                            <li className={validations.minLength ? styles.valid : styles.invalid}>
                                {validations.minLength ? <FaCheckCircle /> : <FaTimesCircle />}
                                At least 8 characters
                            </li>
                            <li className={validations.hasNumber ? styles.valid : styles.invalid}>
                                {validations.hasNumber ? <FaCheckCircle /> : <FaTimesCircle />}
                                Contains a number
                            </li>
                            <li className={validations.hasSpecial ? styles.valid : styles.invalid}>
                                {validations.hasSpecial ? <FaCheckCircle /> : <FaTimesCircle />}
                                Contains a special character
                            </li>
                            <li className={validations.passwordsMatch ? styles.valid : styles.invalid}>
                                {validations.passwordsMatch ? <FaCheckCircle /> : <FaTimesCircle />}
                                Passwords match
                            </li>
                        </ul>
                    </div>

                    <button 
                        type="submit" 
                        className={`${styles.submitButton} ${loading ? styles.loading : ''}`}
                        disabled={loading || !Object.values(validations).every(Boolean)}
                    >
                        {loading ? 'Creating Account...' : 'Create Account'}
                    </button>
                </form>
            )}

            <p className={styles.loginLink}>
                Already have an account? <Link to="/login">Login here</Link>
            </p>
        </div>
    );
};

export default Register;

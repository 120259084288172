// src/components/Login.js
import React, { useState, useContext, useEffect } from 'react';
import api from '../../../api';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, Link, useSearchParams, useLocation } from 'react-router-dom';
import styles from '../styles/Login.module.css';
import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [form, setForm] = useState(() => {
        const rememberedUsername = localStorage.getItem('rememberedUsername');
        return { 
            username: rememberedUsername || '', 
            password: '' 
        };
    });
    const [error, setError] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(() => {
        return !!localStorage.getItem('rememberedUsername');
    });
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const status = searchParams.get('status');
        const messageType = searchParams.get('message');

        // Check for password reset success state
        if (location.state?.passwordReset) {
            setStatusMessage(location.state.message);
            setMessageType('success');
            // Clear the state after showing the message
            window.history.replaceState({}, document.title);
            return;
        }

        if (status && messageType) {
            switch (messageType) {
                case 'email_verified':
                    setStatusMessage('Email verified successfully! Please log in.');
                    setMessageType('success');
                    break;
                case 'already_verified':
                    setStatusMessage('Email already verified. Please log in.');
                    setMessageType('success');
                    break;
                case 'password_reset':
                    setStatusMessage('Password reset successfully! Please log in with your new password.');
                    setMessageType('success');
                    break;
                case 'invalid_token':
                    setStatusMessage('Invalid or expired verification link.');
                    setMessageType('error');
                    break;
                case 'server_error':
                    setStatusMessage('Server error occurred during verification. Please try again.');
                    setMessageType('error');
                    break;
                default:
                    setStatusMessage('');
                    setMessageType('');
            }
        }
    }, [searchParams, location]);

    const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError('');
        
        try {
            // Handle remember me before making the API call
            if (rememberMe) {
                localStorage.setItem('rememberedUsername', form.username);
            } else {
                localStorage.removeItem('rememberedUsername');
            }

            const res = await api.post('/auth/login', {
                ...form,
                rememberMe
            });
            
            // The backend will set the refresh token as an HTTP-only cookie
            // We only need to handle the access token
            login(res.data.access_token);
            
            navigate('/');
        } catch(err) {
            setError(err.response?.data?.message || 'Login failed');
        } finally {
            setLoading(false);
        }
    };

    const handleDemoLogin = async () => {
        setLoading(true);
        setError('');
        
        try {
            const res = await api.get('/auth/demo');
            login(res.data.token, true);
            navigate('/');
        } catch(err) {
            setError(err.response?.data?.message || 'Demo login failed. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Login</h2>
            {statusMessage && (
                <div className={messageType === 'success' ? styles.successMessage : styles.errorMessage}>
                    {statusMessage}
                </div>
            )}
            {error && <p className={styles.errorMessage}>{error}</p>}
            
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="username">Username</label>
                    <div className={styles.inputWrapper}>
                        <FaUser className={styles.inputIcon} />
                        <input 
                            type="text" 
                            name="username" 
                            id="username"
                            placeholder="Username" 
                            value={form.username}
                            onChange={handleChange}
                            required 
                            className={styles.input}
                            disabled={loading}
                            autoComplete="username"
                        />
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="password">Password</label>
                    <div className={styles.inputWrapper}>
                        <FaLock className={styles.inputIcon} />
                        <input 
                            type={showPassword ? "text" : "password"}
                            name="password" 
                            id="password"
                            placeholder="Password" 
                            value={form.password}
                            onChange={handleChange}
                            required 
                            className={styles.input}
                            disabled={loading}
                            autoComplete="current-password"
                        />
                        <button 
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className={styles.showPasswordButton}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>

                <div className={styles.formOptions}>
                    <label className={styles.rememberMe}>
                        <input
                            type="checkbox"
                            name="rememberMe"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        Remember me
                    </label>
                    <Link to="/forgot-password" className={styles.forgotPassword}>
                        Forgot Password?
                    </Link>
                </div>

                <button 
                    type="submit" 
                    className={`${styles.submitButton} ${loading ? styles.loading : ''}`}
                    disabled={loading}
                >
                    {loading ? 'Logging in...' : 'Login'}
                </button>
            </form>

            <p className={styles.registerLink}>
                Don't have an account? <Link to="/register">Register here</Link>.
            </p>

            <div className={styles.demoSection}>
                <div className={styles.divider}>
                    <span>or</span>
                </div>
                <button 
                    onClick={handleDemoLogin} 
                    className={`${styles.demoButton} ${loading ? styles.loading : ''}`}
                    disabled={loading}
                >
                    {loading ? 'Loading Demo...' : 'View Demo'}
                </button>
            </div>
        </div>
    );
};

export default Login;

import { useState, useEffect, useRef } from 'react';

const usePegman = (mapRef, mapWrapperRef, onStreetViewRequested) => {
  const [isPegmanActive, setIsPegmanActive] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [pegmanScreenPosition, setPegmanScreenPosition] = useState(null);
  const [pegmanDragOffset, setPegmanDragOffset] = useState({ x: 0, y: 0 });
  
  const draggingRef = useRef(isDragging);
  
  useEffect(() => {
    draggingRef.current = isDragging;
  }, [isDragging]);

  // Helper function to check if there are streets nearby
  const checkForNearbyStreets = (map, point, bufferDistance = 30) => {
    // Convert buffer distance from pixels to meters based on the current zoom level
    const bounds = map.getBounds();
    const center = bounds.getCenter();
    const pointsPerMeter = map.getZoom() * 2;
    const bufferInMeters = bufferDistance / pointsPerMeter;

    // Create a bounding box around the point
    const sw = map.unproject([point[0] - bufferDistance, point[1] + bufferDistance]);
    const ne = map.unproject([point[0] + bufferDistance, point[1] - bufferDistance]);
    const bbox = [sw.lng, sw.lat, ne.lng, ne.lat];

    // Query features within the bounding box
    const features = map.queryRenderedFeatures(
      [
        [point[0] - bufferDistance, point[1] - bufferDistance],
        [point[0] + bufferDistance, point[1] + bufferDistance]
      ],
      {
        layers: ['pegman-street-coverage']
      }
    );

    return features.length > 0;
  };

  const handlePegmanPlacement = (event, eventType, offsetX, offsetY) => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    // For mouseup events, only proceed if we were dragging
    if (eventType === 'mouseup' && !draggingRef.current) return;

    // Get the correct coordinates based on event type
    let point;
    if (eventType === 'click') {
      point = [event.point.x, event.point.y];
    } else { // mouseup
      const mapRect = mapWrapperRef.current.getBoundingClientRect();
      point = [
        event.clientX - mapRect.left - offsetX,
        event.clientY - mapRect.top - offsetY
      ];
    }

    // Check for nearby streets
    if (checkForNearbyStreets(map, point)) {
      // Find the closest point on the nearest street
      const features = map.queryRenderedFeatures(point, {
        layers: ['pegman-street-coverage'],
        radius: 30
      });

      let snapPoint;
  
      if (features.length > 0) {
        const coords = features[0].geometry.coordinates;

        // Handle different coordinate formats
        function getFirstCoordinate(coords) {
          if (Array.isArray(coords[0])) {
            return getFirstCoordinate(coords[0]);
          }
          return coords;
        }
        
        const firstCoord = getFirstCoordinate(coords);
        snapPoint = {
          lng: firstCoord[0],
          lat: firstCoord[1]
        };
        onStreetViewRequested(snapPoint);
      } else {
        const lngLat = map.unproject(point);
        onStreetViewRequested(lngLat);
      }
    }

    // Clean up based on event type
    if (eventType === 'mouseup') {
      setIsDragging(false);
      draggingRef.current = false;
      setPegmanScreenPosition(null);
    } else if (eventType === 'click') {
      setIsPegmanActive(false);
    }
  };

  const handlePegmanMouseDown = (e) => {
    e.preventDefault();
    
    const clickTimeout = setTimeout(() => {
      setIsPegmanActive((prev) => !prev);
    }, 200);

    const pegmanRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - pegmanRect.left;
    const offsetY = e.clientY - pegmanRect.top;
    setPegmanDragOffset({ x: offsetX, y: offsetY });

    const handleMouseMove = (moveEvent) => {
      clearTimeout(clickTimeout);
      if (!draggingRef.current) {
        setIsDragging(true);
        draggingRef.current = true;
        setIsPegmanActive(true);
      }

      const mapRect = mapWrapperRef.current.getBoundingClientRect();
      const x = moveEvent.clientX - mapRect.left - offsetX;
      const y = moveEvent.clientY - mapRect.top - offsetY;
      setPegmanScreenPosition({ x, y });

      // Optional: Highlight streets within buffer while dragging
      const map = mapRef.current && mapRef.current.getMap();
      if (map) {
        // You can add visual feedback here if desired
      }
    };

    const handleMouseUp = (upEvent) => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      handlePegmanPlacement(upEvent, 'mouseup', offsetX, offsetY);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMapClick = (e) => {
    if (isPegmanActive && !isDragging) {
      handlePegmanPlacement(e, 'click', 0, 0);
    }
  };

  useEffect(() => {
    const map = mapRef.current && mapRef.current.getMap();
    if (map && isPegmanActive) {
      map.on('click', handleMapClick);
    }
    return () => {
      if (map) {
        map.off('click', handleMapClick);
      }
    };
  }, [isPegmanActive, handleMapClick]);

  return {
    isPegmanDragging: isPegmanActive || isDragging,
    pegmanScreenPosition,
    handlePegmanMouseDown,
  };
};

export default usePegman;

// src/features/projects/sgam/hooks/useTerrainEffect.js

import { useEffect } from 'react';

/**
 * Custom hook to manage 3D terrain effects on the map.
 *
 * @param {object} mapRef - Reference to the Mapbox map instance.
 * @param {boolean} isTerrainEnabled - Flag indicating if terrain is enabled.
 * @param {function} setViewport - Function to update the map viewport.
 */
const useTerrainEffect = (mapRef, isTerrainEnabled, setViewport) => {
  useEffect(() => {
    const map = mapRef.current && mapRef.current.getMap();
    if (!map) return;

    /**
     * Applies or removes the terrain based on the isTerrainEnabled flag.
     */
    const applyTerrain = () => {
      if (isTerrainEnabled) {
        // Add terrain source if it doesn't exist
        if (!map.getSource('mapbox-dem')) {
          try {
            map.addSource('mapbox-dem', {
              type: 'raster-dem',
              url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
              tileSize: 512,
              maxzoom: 14,
            });
          } catch (error) {
            console.error('Error adding terrain source:', error);
          }
        }

        // Set the terrain with specified exaggeration
        try {
          map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
        } catch (error) {
          console.error('Error setting terrain:', error);
        }

        // Add sky layer for atmospheric effects if it doesn't exist
        if (!map.getLayer('sky')) {
          try {
            map.addLayer({
              id: 'sky',
              type: 'sky',
              paint: {
                'sky-type': 'atmosphere',
                'sky-atmosphere-sun': [0.0, 0.0],
                'sky-atmosphere-sun-intensity': 15,
              },
            });
          } catch (error) {
            console.error('Error adding sky layer:', error);
          }
        }

        // Update viewport to include pitch for 3D visualization
        setViewport((viewport) => ({ ...viewport, pitch: 60 }));
      } else {
        // Remove terrain
        try {
          map.setTerrain(null);
        } catch (error) {
          console.error('Error removing terrain:', error);
        }

        // Remove sky layer if it exists
        if (map.getLayer('sky')) {
          try {
            map.removeLayer('sky');
          } catch (error) {
            console.error('Error removing sky layer:', error);
          }
        }

        // Reset viewport pitch
        setViewport((viewport) => ({ ...viewport, pitch: 0 }));
      }
    };

    // Initial application based on current state
    applyTerrain();

    /**
     * Event handler for style load events.
     */
    const onStyleLoad = () => {
      applyTerrain();
    };

    // Listen for style.load events to re-apply terrain after style changes
    map.on('style.load', onStyleLoad);

    // Cleanup event listener on unmount
    return () => {
      map.off('style.load', onStyleLoad);
    };
  }, [isTerrainEnabled, mapRef, setViewport]);
};

export default useTerrainEffect;

// src/components/ProjectDetail.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../../../api';
import { AuthContext } from '../../../auth/context/AuthContext';
import { SERVICE_TYPES } from '../../../../constants/serviceTypes';
import styles from '../styles/ProjectDetail.module.css';

import LandslideProjectDetail from '../../landslide/components/LandslideProjectDetail';
import TunnelProjectDetail from '../../tunnel/components/TunnelProjectDetail';
import RockfallProjectDetail from '../../rockfall/components/RockfallProjectDetail';
import SGAMPage from '../../sgam/components/SgamProjectDetail';

const ProjectDetail = () => {
    const { projectId } = useParams();
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProject = async () => {
            setLoading(true);
            setError('');
            try {
                const projectRes = await api.get(`/projects/${projectId}`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });
                const projectData = projectRes.data;
                setProject(projectData);
                // console.log('Project Data:', projectData);
            } catch (err) {
                console.error('Error fetching project details:', err);
                setError('Failed to load project details.');
            } finally {
                setLoading(false);
            }
        };

        fetchProject();
    }, [projectId, auth.token]);

    if (loading) {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{project ? project.name : 'Project Detail'}</h2>
                    <button onClick={() => navigate(-1)} className={styles.backButton}>Back</button>
                </div>
                <p className={styles.loading}>Loading project details...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{project ? project.name : 'Project Detail'}</h2>
                    <button onClick={() => navigate(-1)} className={styles.backButton}>Back</button>
                </div>
                <p className={styles.error}>{error}</p>
            </div>
        );
    }

    if (!project) {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <h2 className={styles.title}>Project Detail</h2>
                    <button onClick={() => navigate(-1)} className={styles.backButton}>Back</button>
                </div>
                <p className={styles.error}>Project not found.</p>
            </div>
        );    
    }

    const serviceName = project.service.name;

    // Render the appropriate component based on service type
    if (serviceName === SERVICE_TYPES.LANDSLIDE_MONITORING) {
        return <LandslideProjectDetail project={project} />;
    } else if (serviceName === SERVICE_TYPES.TUNNEL_CONVERGENCES) {
        return <TunnelProjectDetail project={project} />;
    } else if (serviceName === SERVICE_TYPES.ROCKFALL_DETECTION) {
        return <RockfallProjectDetail project={project} />;
    }  else if (serviceName === SERVICE_TYPES.SGAM){
        return <SGAMPage project={project} />;
    }  else {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{project.name}</h2>
                    <button onClick={() => navigate(-1)} className={styles.backButton}>Back</button>
                </div>
                <p className={styles.error}>Unsupported service type.</p>
            </div>
        );
    }
};

export default ProjectDetail;

// src/components/RockfallProjectDetail/RockfallImageViewer.js

import React, { useRef, useCallback } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styles from '../../common/styles/ProjectDetail.module.css';

const RockfallImageViewer = ({ displayedImage, cycleStep, onNextCycle, onPrevCycle, onImageLoad }) => {
  const imageRef = useRef(null);

  const handleImageLoad = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      if (naturalWidth && naturalHeight) {
        const aspectRatio = (naturalWidth * 0.7) / naturalHeight;
        onImageLoad(aspectRatio);
      }
    }
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === '1') {
        onNextCycle();
      } else if (e.key === '2') {
        onPrevCycle();
      }
    },
    [onNextCycle, onPrevCycle]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // Retrieve the base URL from environment variables
  const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL || '';

  // Construct the full image URL
  const imageSrc = `${imageBaseURL}/${displayedImage.imageUrl}`;

  return (
    <div className={styles.imageViewer}>
      <div className={styles.imageContainer}>
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          wheel={{ step: 0.1 }}
          doubleClick={{ disabled: true }}
          limitToBounds={true}
          centerZoomedOut={true}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <div className={styles.controls}>
                <button
                  onClick={() => zoomIn()}
                  className={styles.controlButton}
                  aria-label="Zoom In"
                >
                  +
                </button>
                <button
                  onClick={() => zoomOut()}
                  className={styles.controlButton}
                  aria-label="Zoom Out"
                >
                  -
                </button>
                <button
                  onClick={() => resetTransform()}
                  className={styles.controlButton}
                  aria-label="Reset Zoom"
                >
                  Reset
                </button>
              </div>
              <TransformComponent>
                <div style={{ position: 'relative' }}>
                  <img
                    ref={imageRef}
                    src={imageSrc}
                    alt={`Image ${
                      cycleStep === 0
                        ? 'Two-Timestamp'
                        : cycleStep === 1
                        ? 'From Single'
                        : 'To Single'
                    }`}
                    className={styles.displayedImage}
                    loading="lazy"
                    onLoad={handleImageLoad}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};

export default RockfallImageViewer;

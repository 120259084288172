// frontend/src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import api from '../../../api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        token: localStorage.getItem('token'),
        user: null,
        isDemo: false
    });

    const login = (token, isDemo = false) => {
        localStorage.setItem('token', token);
        setAuth(prev => ({ ...prev, token, isDemo }));
    };

    // Logout function to clear auth and redirect to login
    const logout = async (redirectToLogin = true) => {
        try {
            // Call logout endpoint to invalidate refresh token
            await api.post('/auth/logout');
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            // Clear token but keep rememberedUsername if it exists
            localStorage.removeItem('token');
            setAuth({ token: null, user: null, isDemo: false });
            if (redirectToLogin) {
                // Use replace to prevent back navigation to protected routes
                window.location.replace('/login');
            }
        }
    };

    useEffect(() => {
        if (auth.token) {
            try {
                const decoded = jwtDecode(auth.token);
                
                // Check if the token has expired
                if (decoded.exp * 1000 < Date.now()) {
                    console.log('Token has expired.');
                    logout(true);
                } else {
                    setAuth(prev => ({ ...prev, user: decoded }));
                    
                    // Optional: Set a timeout to automatically logout when the token expires
                    const timeout = decoded.exp * 1000 - Date.now();
                    const timer = setTimeout(() => {
                        logout(true);
                    }, timeout);
                    
                    // Clear the timeout if the component unmounts or token changes
                    return () => clearTimeout(timer);
                }
            } catch (err) {
                console.error('Invalid token:', err);
                logout(true);
            }
        }
    }, [auth.token]);

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

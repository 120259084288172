// src/components/LandslideProjectDetail/utils.js

import { jStat } from 'jstat'; // Import jStat for statistical calculations

/**
 * Calculates the average displacement timeseries with confidence interval filtering.
 *
 * @param {Array<Array<{ date: string, displacement: number }>>} timeseriesArray - An array of timeseries datasets.
 * @param {number} maxConf - The maximum allowed confidence interval. Dates exceeding this will be removed.
 * @returns {Array<{ date: string, displacement: number }>} - The filtered average timeseries.
 */
export const calculateAverageTimeseries = (timeseriesArray, maxConf = 1) => {
    if (timeseriesArray.length === 0) return [];

    const merged = {};

    // Aggregate data: sum, sum of squares, and count for each date
    timeseriesArray.forEach(timeseries => {
        timeseries.forEach(entry => {
            const { date, displacement } = entry;

            if (!merged[date]) {
                merged[date] = { 
                    total: 0, 
                    sumOfSquares: 0, 
                    count: 0 
                };
            }

            merged[date].total += displacement;
            merged[date].sumOfSquares += displacement ** 2;
            merged[date].count += 1;
        });
    });

    const averageTimeseries = Object.entries(merged)
        .map(([date, stats]) => {
            const { total, sumOfSquares, count } = stats;

            // Ensure there are at least two data points to calculate variance
            if (count < 2) {
                return null; // Insufficient data; exclude this date
            }

            const mean = total / count;

            // Calculate variance
            const variance = (sumOfSquares - (total ** 2) / count) / (count - 1);
            const stdDev = Math.sqrt(variance);

            // Calculate Standard Error of the Mean (SEM)
            const sem = stdDev / Math.sqrt(count);

            // Degrees of freedom
            const dof = count - 1;

            // t-value for 95% confidence
            const alpha = 0.05;
            const tVal = jStat.studentt.inv(1 - alpha / 2, dof);

            // Confidence interval
            const confInt = sem * tVal;

            // Determine if the confidence interval is within the threshold
            if (confInt <= maxConf) {
                return {
                    date,
                    displacement: Number(mean.toFixed(2))
                };
            } else {
                return null; // Confidence interval too high; exclude this date
            }
        })
        .filter(entry => entry !== null) // Remove excluded dates
        .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date

    return averageTimeseries;
};

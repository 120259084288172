// src/components/LandslideProjectDetail/LandslideProjectDetail.js

import React, { useState, useEffect, useContext, useRef, useMemo} from 'react';
import api from '../../../../api';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../auth/context/AuthContext';
import styles from '../../common/styles/ProjectDetail.module.css';
import { FaCalendarAlt, FaFlag } from 'react-icons/fa';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Popup from './Popup';
import ColorBar from './ColorBar';
import useDisplacementData from '../hooks/useDisplacementData';
import usePopupManagement from '../hooks/usePopupManagement';
import useSelectionMode from '../hooks/useSelectionMode';
import { calculateAverageTimeseries } from './utils';
import useCanvasDrawing from '../hooks/useCanvasDrawing';
import * as d3 from 'd3'; 

const LandslideProjectDetail = ({ project }) => {
    // Previous state and hooks remain the same
    const projectId = project._id;
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext);
    const [error, setError] = useState('');
    const [displacementType, setDisplacementType] = useState('EW');
    const [landslideReferenceImage, setLandslideReferenceImage] = useState(null);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [imageAspectRatio, setImageAspectRatio] = useState(null);

    // Refs for canvases and image
    const staticCanvasRef = useRef(null);
    const highlightCanvasRef = useRef(null);
    const imageRef = useRef(null);
    
    const [transformState, setTransformState] = useState({
        scale: 1,
        positionX: 0,
        positionY: 0,
    });

    // Custom Hooks
    const {
        availableDates,
        selectedDateIndex,
        displacementData,
        handleDateChange,
        error: displacementError,
    } = useDisplacementData(projectId, displacementType, auth.token);

    const {
        popups,
        addSinglePopup,
        addMultiPopup,
        removePopup,
        fetchTimeseriesData,
        fetchTimeseriesForMultiplePoints
    } = usePopupManagement(projectId, displacementType, auth.token, calculateAverageTimeseries);

    const {
        selectionMode,
        toggleSelectionMode,
        polygonPoints,
        setPolygonPoints,
        currentMousePos,
        setCurrentMousePos,
        isDrawing,
        isFinalizing,
        addPolygonPoint,
    } = useSelectionMode();

    // Previous effects and handlers remain the same
    useEffect(() => {
        const fetchLandslideData = async () => {
            try {
                const landslideDataRes = await api.get(`/landslideData/${projectId}/data`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });
                setLandslideReferenceImage(landslideDataRes.data.referenceImage);
            } catch {
                setError('Failed to load landslide data.');
            }
        };

        fetchLandslideData();
    }, [projectId, auth.token]);

    useEffect(() => {
        if (displacementError) {
            setError(displacementError);
        }
    }, [displacementError]);

    const handleDisplacementTypeToggle = () => {
        const newType = displacementType === 'EW' ? 'NS' : 'EW';
        setDisplacementType(newType);
    };

    const handleImageLoad = () => {
        if (imageRef.current) {
            const { naturalWidth, naturalHeight } = imageRef.current;
            if (naturalWidth && naturalHeight) {
                setImageAspectRatio(naturalWidth * 0.7 / naturalHeight);
                setIsImageLoaded(true);
            }
        }
    };

        //  Compute min and max displacement values for color scaling
        const [minDisplacement, setMinDisplacement] = useState(0);
        const [maxDisplacement, setMaxDisplacement] = useState(0);
    
        useEffect(() => {
            if (displacementData && displacementData.length > 0) {
                const values = displacementData.map(d => d.displacement);
                
                // Calculate 95th percentile
                const sortedValues = [...values].sort((a, b) => a - b);
                const p95Index = Math.floor(sortedValues.length * 0.8);
                const p95Value = sortedValues[p95Index];
                const p5Value = sortedValues[Math.floor(sortedValues.length * 0.2)];
                
                // Find the larger absolute value to create a symmetric range
                const maxAbsValue = Math.max(Math.abs(p95Value), Math.abs(p5Value));
                
                setMinDisplacement(-15);
                setMaxDisplacement(15);
            }
        }, [displacementData]);
    
        // Define the color scale for displacement markers
        const colorScale = useMemo(() => {
            return d3.scaleSequential(d3.interpolateRgbBasis([
                "#00007f",
                "#0000ff",
                "#00ffff",
                "#00ff00",
                "#ffff00",
                "#ff0000",
                "#7f0000",
            ])).domain([minDisplacement, maxDisplacement]);
        }, [minDisplacement, maxDisplacement]);

    const { renderCanvases } = useCanvasDrawing({
        staticCanvasRef,
        highlightCanvasRef,
        imageRef,
        isImageLoaded,
        displacementData,
        popups,
        selectionMode,
        polygonPoints,
        currentMousePos,
        isDrawing,
        isFinalizing,
        addPolygonPoint,
        addSinglePopup,
        addMultiPopup,
        fetchTimeseriesData,
        fetchTimeseriesForMultiplePoints,
        setPolygonPoints,
        setCurrentMousePos,
        setError,
        transformState,
    });

    const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL || '';
    const imageSrc = `${imageBaseURL}/${landslideReferenceImage}`;

    return (
        <div className={styles.container} style={imageAspectRatio ? { '--aspect-ratio': imageAspectRatio } : {}}>
            {/* Header */}
            <div className={styles.header}>
                <h2 className={styles.title}>{project.name}</h2>
                <button onClick={() => navigate(-1)} className={styles.backButton}>Back</button>
            </div>

            {/* Controls and ColorBar */}
            <div className={styles.landslideFilterContainer}>
                <div className={styles.controlRow}>
                    <label className={styles.filterLabel}>Type</label>
                    <button onClick={handleDisplacementTypeToggle} className={styles.filterSelect}>
                        {displacementType === 'EW' ? 'Horizontal' : 'Vertical'}
                    </button>
                </div>
                <div className={styles.controlRow}>
                    <label className={styles.filterLabel}>Mode</label>
                    <button onClick={toggleSelectionMode} className={styles.filterSelect}>
                        {selectionMode === 'Disabled' ? 'Enable Selection' : 
                            selectionMode === 'Point' ? 'Point Selection' : 'Polygon Selection'}
                    </button>
                </div>
                <div className={styles.colorBarContainer}>
                    <ColorBar
                        displacementType={displacementType}
                        minDisplacement={minDisplacement}
                        maxDisplacement={maxDisplacement}
                        colorScale={colorScale}
                    />
                </div>
            </div>

            {/* Image Viewer */}
            <div className={styles.imageViewer}>
                <div className={styles.imageContainer} style={{ cursor: selectionMode !== 'Disabled' ? 'crosshair' : 'default' }}>
                    <TransformWrapper
                        initialScale={1}
                        initialPositionX={0}
                        initialPositionY={0}
                        wheel={{ step: 0.1, disabled: selectionMode === 'Polygon' }}
                        doubleClick={{ disabled: true }}
                        limitToBounds={true}
                        centerZoomedOut={true}
                        panning={{ disabled: selectionMode === 'Polygon' }}
                        zoomAnimation={{ disabled: selectionMode === 'Polygon' }}
                        pinch={{ disabled: selectionMode === 'Polygon' }}
                        onZoomStop={(ref) => {
                            const { scale, positionX, positionY } = ref.state;
                            setTransformState({ scale, positionX, positionY });
                        }}
                        onPanningStop={(ref) => {
                            const { scale, positionX, positionY } = ref.state;
                            setTransformState({ scale, positionX, positionY });
                        }}
                    >
                        {({ zoomIn, zoomOut, resetTransform }) => (
                            <>
                                <div className={styles.controls}>
                                    <button onClick={() => zoomIn()} className={styles.controlButton} aria-label="Zoom In">+</button>
                                    <button onClick={() => zoomOut()} className={styles.controlButton} aria-label="Zoom Out">-</button>
                                    <button onClick={() => resetTransform()} className={styles.controlButton} aria-label="Reset Zoom">Reset</button>
                                </div>
                                <TransformComponent>
                                    <div style={{ position: 'relative' }}>
                                        <img 
                                            ref={imageRef}
                                            src={imageSrc}
                                            alt={`Reference Image`} 
                                            className={styles.displayedImage}
                                            loading="lazy"
                                            onLoad={handleImageLoad}
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                        {renderCanvases()}
                                    </div>
                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                </div>

                {/* Slider */}
                <div className={styles.sliderContainer}>
                    {availableDates.length > 0 && (
                        <input 
                            type="range" 
                            min="0" 
                            max={availableDates.length -1} 
                            value={selectedDateIndex} 
                            onChange={handleDateChange} 
                            className={styles.slider}
                        />
                    )}
                    <div className={styles.timestampContainer}>
                        <div className={styles.timestampBox}>
                            <div className={styles.iconLabelRow}>
                                <FaCalendarAlt className={styles.icon} />
                                <span className={styles.timestampLabel}>From:</span>
                            </div>
                            <div className={styles.timestampValue}>
                                {availableDates.length > 0 ? new Date(availableDates[0]).toLocaleString() : 'N/A'}
                            </div>
                        </div>
                        <div className={styles.timestampBox}>
                            <div className={styles.iconLabelRow}>
                                <FaFlag className={styles.icon} />
                                <span className={styles.timestampLabel}>To:</span>
                            </div>
                            <div className={styles.timestampValue}>
                                {availableDates[selectedDateIndex] ? new Date(availableDates[selectedDateIndex]).toLocaleString() : 'N/A'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Popup Overlay */}
            <div className={styles.popupOverlay}>
                {popups.map(popup => (
                    <Popup
                        key={popup.id}
                        popup={popup}
                        removePopup={removePopup}
                    />
                ))}
            </div>

            {/* Display error messages if any */}
            {error && (
                <div className={styles.error}>
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

export default LandslideProjectDetail;

// src/features/projects/sgam/hooks/useStreetCoverageLayer.js

import { useEffect } from 'react';

const useStreetCoverageLayer = (mapRef, isPegmanDragging) => {
  useEffect(() => {
    const map = mapRef.current && mapRef.current.getMap();
    if (!map) return;

    if (isPegmanDragging) {
      if (!map.getSource('mapbox-streets')) {
        map.addSource('mapbox-streets', {
          type: 'vector',
          url: 'mapbox://mapbox.mapbox-streets-v8'
        });
      }

      if (!map.getLayer('pegman-street-coverage')) {
        map.addLayer({
          id: 'pegman-street-coverage',
          type: 'line',
          source: 'mapbox-streets',
          'source-layer': 'road',
          paint: {
            'line-color': '#ffcc00',
            'line-width': 4,
            'line-opacity': 0.8
          },
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          }
        });

        // Add hover effect
        map.addLayer({
          id: 'pegman-street-coverage-hover',
          type: 'line',
          source: 'mapbox-streets',
          'source-layer': 'road',
          paint: {
            'line-color': '#ff9900',
            'line-width': 6,
            'line-opacity': 0
          },
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          }
        });

        // Add hover interaction
        map.on('mousemove', 'pegman-street-coverage', (e) => {
          map.setPaintProperty(
            'pegman-street-coverage-hover',
            'line-opacity',
            1
          );
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'pegman-street-coverage', () => {
          map.setPaintProperty(
            'pegman-street-coverage-hover',
            'line-opacity',
            0
          );
          map.getCanvas().style.cursor = '';
        });
      }
    } else {
      // Clean up layers and event listeners
      if (map.getLayer('pegman-street-coverage-hover')) {
        map.removeLayer('pegman-street-coverage-hover');
      }
      if (map.getLayer('pegman-street-coverage')) {
        map.removeLayer('pegman-street-coverage');
      }
      map.off('mousemove', 'pegman-street-coverage');
      map.off('mouseleave', 'pegman-street-coverage');
    }

    // Cleanup function
    return () => {
      if (map) {
        if (map.getLayer('pegman-street-coverage-hover')) {
          map.removeLayer('pegman-street-coverage-hover');
        }
        if (map.getLayer('pegman-street-coverage')) {
          map.removeLayer('pegman-street-coverage');
        }
        map.off('mousemove', 'pegman-street-coverage');
        map.off('mouseleave', 'pegman-street-coverage');
      }
    };
  }, [isPegmanDragging]);
};

export default useStreetCoverageLayer;

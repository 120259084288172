import api from '../../../../api'; // Ensure this path correctly points to your Axios instance

/**
 * Fetch the GeoJSON data for a specific SGAM project
 * @param {string} projectId - The ID of the SGAM project
 * @returns {Promise<Object>} - The GeoJSON data and upload timestamp
 */
export const getSGAMGeoJSON = async (projectId) => {
    try {
        const response = await api.get(`sgamData/${projectId}/geojson`);
        return response.data; // Expected format: { project, geojson, uploadedAt }
    } catch (error) {
        console.error('Error fetching SGAM GeoJSON:', error);
        throw error;
    }
};

/**
 * Fetch all georeferenced images for a specific SGAM project
 * @param {string} projectId - The ID of the SGAM project
 * @returns {Promise<Array>} - Array of georeferenced images
 */
export const getSGAMGeoreferencedImages = async (projectId) => {
    try {
        const response = await api.get(`georeferenced-images/${projectId}`);
        return response.data; // Expected format: Array of GeoreferencedImage objects
    } catch (error) {
        console.error('Error fetching SGAM Georeferenced Images:', error);
        throw error;
    }
};

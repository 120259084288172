// src/components/LandslideProjectDetail/hooks/useDisplacementData.js

import { useState, useEffect, useCallback } from 'react';
import api from '../../../../api';
import debounce from 'lodash.debounce';

const useDisplacementData = (projectId, displacementType, authToken) => {
    const [availableDates, setAvailableDates] = useState([]);
    const [selectedDateIndex, setSelectedDateIndex] = useState(0);
    const [displacementData, setDisplacementData] = useState([]);
    const [error, setError] = useState('');

    const debouncedFetchAvailableDates = useCallback(
        debounce(async (currentProjectId, currentDisplacementType, token) => {
            try {
                const datesRes = await api.get(`/landslideData/${currentProjectId}/${currentDisplacementType}/dates`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const dates = datesRes.data.dates.map(dateStr => new Date(dateStr));
                dates.sort((a, b) => a - b);
                setAvailableDates(dates);
                setSelectedDateIndex(dates.length > 0 ? dates.length - 1 : 0);
            } catch {
                setError('Failed to fetch available dates for displacement data.');
            }
        }, 300),
        []
    );

    const debouncedFetchDisplacementData = useCallback(
        debounce(async (currentProjectId, currentDisplacementType, currentSelectedDate, token) => {
            if (!currentSelectedDate) return;
            try {
                const formattedDate = currentSelectedDate.toISOString();
                const displacementRes = await api.get(`/landslideData/${currentProjectId}/${currentDisplacementType}/displacements`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: { date: formattedDate }
                });
                setDisplacementData(displacementRes.data.displacements);
            } catch {
                setError('Failed to fetch displacement data.');
            }
        }, 300),
        []
    );

    useEffect(() => {
        if (projectId && displacementType && authToken) {
            debouncedFetchAvailableDates(projectId, displacementType, authToken);
        }
        return () => {
            debouncedFetchAvailableDates.cancel();
        };
    }, [debouncedFetchAvailableDates, projectId, displacementType, authToken]);

    useEffect(() => {
        if (availableDates.length === 0) return;
        const selectedDate = availableDates[selectedDateIndex];
        if (!selectedDate) return;
        debouncedFetchDisplacementData(projectId, displacementType, selectedDate, authToken);
        return () => {
            debouncedFetchDisplacementData.cancel();
        };
    }, [debouncedFetchDisplacementData, projectId, displacementType, availableDates, selectedDateIndex, authToken]);

    const handleDateChange = (e) => {
        const newIndex = Number(e.target.value);
        setSelectedDateIndex(newIndex);
    };

    return {
        availableDates,
        selectedDateIndex,
        displacementData,
        handleDateChange,
        error,
    };
};

export default useDisplacementData;

// src/components/UserManagement.js
import React, { useEffect, useState, useContext } from 'react';
import api from '../../../api';
import { AuthContext } from '../../auth/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/UserManagement.module.css';

const UserManagement = () => {
    const { auth } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]); // State for all projects
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [newRole, setNewRole] = useState('');
    const [projectsToAssign, setProjectsToAssign] = useState([]); // Projects to assign
    const [projectsToUnassign, setProjectsToUnassign] = useState([]); // Projects to unassign
    const [modalType, setModalType] = useState(null); // New state to manage modal types
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch users
                const usersRes = await api.get(`/users`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });

                // Fetch projects
                const projectsRes = await api.get(`/projects`, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                });

                setUsers(usersRes.data);
                setProjects(projectsRes.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to load data.');
                setLoading(false);
            }
        };
        fetchData();
    }, [auth.token]);

    // Handle role change button click
    const handleRoleChange = (userId) => {
        const user = users.find(u => u._id === userId);
        if(user) {
            setSelectedUser(user);
            setNewRole(user.role === 'admin' ? 'user' : 'admin'); // Toggle role
            setModalType('roleChange'); // Set modal type to roleChange
        }
    };

    // Submit role change
    const submitRoleChange = async () => {
        if(!selectedUser) return;

        try {
            const res = await api.put(`/users/${selectedUser._id}/role`, {
                role: newRole
            }, {
                headers: { 'Authorization': `Bearer ${auth.token}` }
            });

            // Update local state
            setUsers(users.map(user => user._id === selectedUser._id ? { ...user, role: newRole } : user));
            setSelectedUser(null);
            setNewRole('');
            setModalType(null); // Reset modal type
            alert(res.data.message);
        } catch(err) {
            console.error(err);
            alert(err.response?.data?.message || 'Failed to update role.');
        }
    };

    // Handle project assignment button click
    const handleManageProjects = (user) => {
        setSelectedUser(user);
        setProjectsToAssign([]); // Reset assignments
        setProjectsToUnassign([]); // Reset unassignments
        setModalType('manageProjects'); // Set modal type to manageProjects
    };

    // Toggle project selection for assignment
    const toggleAssignProject = (projectId) => {
        if (projectsToAssign.includes(projectId)) {
            setProjectsToAssign(projectsToAssign.filter(id => id !== projectId));
        } else {
            setProjectsToAssign([...projectsToAssign, projectId]);
        }
    };

    // Toggle project selection for unassignment
    const toggleUnassignProject = (projectId) => {
        if (projectsToUnassign.includes(projectId)) {
            setProjectsToUnassign(projectsToUnassign.filter(id => id !== projectId));
        } else {
            setProjectsToUnassign([...projectsToUnassign, projectId]);
        }
    };

    // Submit project assignments and unassignments
    const submitProjectManagement = async () => {
        if (!selectedUser) return;

        try {
            // Assign projects
            const assignPromises = projectsToAssign.map(projectId =>
                api.put(`/projects/${projectId}/assign`, {
                    userIds: [selectedUser._id]
                }, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                })
            );

            // Unassign projects
            const unassignPromises = projectsToUnassign.map(projectId =>
                api.put(`/projects/${projectId}/unassign`, {
                    userIds: [selectedUser._id]
                }, {
                    headers: { 'Authorization': `Bearer ${auth.token}` }
                })
            );

            // Execute all requests in parallel
            await Promise.all([...assignPromises, ...unassignPromises]);

            // Update local state
            const updatedProjects = projects.map(project => {
                if (projectsToAssign.includes(project._id)) {
                    return { ...project, assignedUsers: [...project.assignedUsers, selectedUser._id] };
                }
                if (projectsToUnassign.includes(project._id)) {
                    return { ...project, assignedUsers: project.assignedUsers.filter(id => id !== selectedUser._id) };
                }
                return project;
            });
            setProjects(updatedProjects);

            // Update user's assignedProjects
            setUsers(users.map(user => {
                if (user._id === selectedUser._id) {
                    // Assign: Add new projects
                    const newAssigned = [...(user.assignedProjects || []), ...projectsToAssign];
                    // Unassign: Remove projects
                    const finalAssigned = newAssigned.filter(id => !projectsToUnassign.includes(id));
                    return { ...user, assignedProjects: finalAssigned };
                }
                return user;
            }));

            setSelectedUser(null);
            setProjectsToAssign([]);
            setProjectsToUnassign([]);
            setModalType(null); // Reset modal type
            alert('Project assignments updated successfully.');
        } catch(err) {
            console.error(err);
            alert(err.response?.data?.message || 'Failed to update project assignments.');
        }
    };

    if (loading) {
        return <div className={styles.container}><p className={styles.loading}>Loading users and projects...</p></div>;
    }

    if (error) {
        return <div className={styles.container}><p className={styles.error}>{error}</p></div>;
    }

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>User Management</h3>
            
            {/* Wrapper for the table */}
            <div className={styles.tableWrapper}>
                <table className={styles.userTable}>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Change Role</th>
                            <th>Assigned Projects</th>
                            <th>Manage Projects</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user._id}>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>
                                    <span className={`${styles.status} ${user.isVerified ? styles.verified : styles.unverified}`}>
                                        {user.isVerified ? 'Verified' : 'Unverified'}
                                    </span>
                                </td>
                                <td>
                                    {user.email !== auth.user.email ? (
                                        <button 
                                            onClick={() => handleRoleChange(user._id)} 
                                            className={styles.changeRoleButton}
                                            disabled={!user.isVerified}
                                        >
                                            {user.role === 'admin' ? 'Demote to User' : 'Promote to Admin'}
                                        </button>
                                    ) : (
                                        <span>--</span>
                                    )}
                                </td>
                                <td className={styles.assignedProjectsCell}>
                                    <div className={styles.assignedProjectsContent}>
                                        {user.assignedProjects && user.assignedProjects.length > 0
                                            ? user.assignedProjects.map(projId => {
                                                const project = projects.find(p => p._id === projId);
                                                return project ? project.name : 'Unknown Project';
                                            }).join(', ')
                                            : 'None'}
                                    </div>
                                </td>
                                <td>
                                    <button 
                                        onClick={() => handleManageProjects(user)} 
                                        className={styles.assignProjectButton}
                                        disabled={!user.isVerified}
                                    >
                                        Manage Projects
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Role Change Modal */}
            {modalType === 'roleChange' && selectedUser && newRole && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h4 className={styles.modalTitle}>Change Role for {selectedUser.email}</h4>
                        <p className={styles.modalText}>Are you sure you want to change the role to <strong>{newRole}</strong>?</p>
                        <div className={styles.modalButtons}>
                            <button onClick={submitRoleChange} className={styles.confirmButton}>Confirm</button>
                            <button onClick={() => { setSelectedUser(null); setNewRole(''); setModalType(null); }} className={styles.cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Project Management Modal */}
            {modalType === 'manageProjects' && selectedUser && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h4 className={styles.modalTitle}>Manage Projects for {selectedUser.email}</h4>
                        <div className={styles.projectSections}>
                            {/* Assigned Projects */}
                            <div className={styles.projectSection}>
                                <h5>Assigned Projects</h5>
                                <ul className={styles.projectList}>
                                    {selectedUser.assignedProjects && selectedUser.assignedProjects.length > 0 ? (
                                        selectedUser.assignedProjects.map(projId => {
                                            const project = projects.find(p => p._id === projId);
                                            return project ? (
                                                <li key={projId} className={styles.projectItem}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={projectsToUnassign.includes(projId)}
                                                            onChange={() => toggleUnassignProject(projId)}
                                                        />
                                                        {project.name}
                                                    </label>
                                                </li>
                                            ) : (
                                                <li key={projId} className={styles.projectItem}>Unknown Project</li>
                                            );
                                        })
                                    ) : (
                                        <li className={styles.projectItem}>No projects assigned.</li>
                                    )}
                                </ul>
                                <button
                                    onClick={submitProjectManagement}
                                    className={styles.unassignButton}
                                    disabled={projectsToUnassign.length === 0}
                                >
                                    Unassign Selected
                                </button>
                            </div>

                            {/* Available Projects */}
                            <div className={styles.projectSection}>
                                <h5>Available Projects</h5>
                                <ul className={styles.projectList}>
                                    {projects.filter(p => !selectedUser.assignedProjects.includes(p._id)).length > 0 ? (
                                        projects.filter(p => !selectedUser.assignedProjects.includes(p._id)).map(project => (
                                            <li key={project._id} className={styles.projectItem}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={projectsToAssign.includes(project._id)}
                                                        onChange={() => toggleAssignProject(project._id)}
                                                    />
                                                    {project.name}
                                                </label>
                                            </li>
                                        ))
                                    ) : (
                                        <li className={styles.projectItem}>No available projects to assign.</li>
                                    )}
                                </ul>
                                <button
                                    onClick={submitProjectManagement}
                                    className={styles.assignButton}
                                    disabled={projectsToAssign.length === 0}
                                >
                                    Assign Selected
                                </button>
                            </div>
                        </div>
                        <div className={styles.modalButtons}>
                            <button onClick={() => { setSelectedUser(null); setProjectsToAssign([]); setProjectsToUnassign([]); setModalType(null); }} className={styles.closeButton}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

};

export default UserManagement;

// /client/src/components/UI/ZoomToProjectButton.js
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import turfBbox from '@turf/bbox';

const ZoomToProjectButton = ({ mapRef, geojsonDataArray }) => {
  // Calculate bounding box and fit to bounds
  const zoomToBbox = useCallback(() => {
    if (geojsonDataArray && geojsonDataArray.length > 0 && mapRef.current) {
      const allFeatures = geojsonDataArray.reduce((acc, item) => {
        if (item.geojson && item.geojson.features) {
          return acc.concat(item.geojson.features);
        }
        return acc;
      }, []);
      const combinedGeojson = {
        type: 'FeatureCollection',
        features: allFeatures,
      };
      const bbox = turfBbox(combinedGeojson); // [minX, minY, maxX, maxY]
      mapRef.current.fitBounds(
        [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[3]],
        ],
        { padding: 40, duration: 1000 }
      );
    }
  }, [geojsonDataArray, mapRef]);

  return (
    <button
      onClick={zoomToBbox}
      title="Zoom to Project"
      style={{
        width: '30px',
        height: '30px',
        background: 'white',
        border: '0',
        cursor: 'pointer',
        borderRadius: '4px',
        boxShadow: '0 1px 3px rgba(0,0,0,.5)',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="#373636"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="6"></circle>
        <line x1="12" y1="3" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="21"></line>
        <line x1="21" y1="12" x2="18" y2="12"></line>
        <line x1="6" y1="12" x2="3" y2="12"></line>
        <circle cx="12" cy="12" r="2"></circle>
        <circle cx="12" cy="12" r="1"></circle>
      </svg>
    </button>
  );
};

ZoomToProjectButton.propTypes = {
  mapRef: PropTypes.object.isRequired,
  geojsonDataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ZoomToProjectButton;

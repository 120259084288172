// src/components/TunnelProjectDetail/TunnelImageViewer.js

import React, { useRef, useCallback } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styles from '../../common/styles/ProjectDetail.module.css';

const TunnelImageViewer = ({ displayedImage, onImageLoad }) => {
  const imageRef = useRef(null);

  const handleImageLoad = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      if (naturalWidth && naturalHeight) {
        const aspectRatio = (naturalWidth * 0.7) / naturalHeight;
        onImageLoad(aspectRatio);
      }
    }
  };

  // Retrieve the base URL from environment variables
  const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL || '';

  // Construct the full image URL
  const imageSrc = `${imageBaseURL}/${displayedImage.imageUrl}`;
  
  return (
    <div className={styles.imageContainer}>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        wheel={{ step: 0.1 }}
        doubleClick={{ disabled: true }}
        limitToBounds={true}
        centerZoomedOut={true}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div className={styles.controls}>
              <button
                onClick={() => zoomIn()}
                className={styles.controlButton}
                aria-label="Zoom In"
              >
                +
              </button>
              <button
                onClick={() => zoomOut()}
                className={styles.controlButton}
                aria-label="Zoom Out"
              >
                -
              </button>
              <button
                onClick={() => resetTransform()}
                className={styles.controlButton}
                aria-label="Reset Zoom"
              >
                Reset
              </button>
            </div>
            <TransformComponent>
              <div style={{ position: 'relative' }}>
                <img
                  ref={imageRef}
                  src={imageSrc}
                  alt={`Image ${displayedImage.progressiva}`}
                  className={styles.displayedImage}
                  loading="lazy"
                  onLoad={handleImageLoad}
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  );
};

export default TunnelImageViewer;

// src/components/RockfallProjectDetail/hooks/useRockfallCycle.js

import { useState, useEffect } from 'react';

const useRockfallCycle = (imageGroups) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cycleStep, setCycleStep] = useState(0); // 0: two-timestamp, 1: fromSingle, 2: toSingle
  const [displayedImage, setDisplayedImage] = useState(null);

  // Initialize when imageGroups change
  useEffect(() => {
    if (imageGroups.length > 0) {
      setCurrentIndex(0);
      setCycleStep(0);
      setDisplayedImage(imageGroups[0].twoImg);
    } else {
      setDisplayedImage(null);
    }
  }, [imageGroups]);

  // Update displayedImage when currentIndex changes
  useEffect(() => {
    if (imageGroups.length > 0 && imageGroups[currentIndex]) {
      setCycleStep(0);
      setDisplayedImage(imageGroups[currentIndex].twoImg);
    }
  }, [currentIndex, imageGroups]);

  const handleNextCycle = () => {
    const currentGroup = imageGroups[currentIndex];
    if (!currentGroup) return;

    if (cycleStep === 0 && currentGroup.fromSingle) {
      setCycleStep(1);
      setDisplayedImage(currentGroup.fromSingle);
    } else if (cycleStep === 1 && currentGroup.toSingle) {
      setCycleStep(2);
      setDisplayedImage(currentGroup.toSingle);
    } else if (cycleStep === 2 && currentGroup.twoImg) {
      // Cycle back to twoImg within the same group
      setCycleStep(0);
      setDisplayedImage(currentGroup.twoImg);
    } else {
      console.warn(
        `Image for cycleStep ${cycleStep + 1} is missing in group ${currentIndex}. Staying on current image.`
      );
      // Do not change cycleStep or displayedImage
    }
  };

  const handlePrevCycle = () => {
    const currentGroup = imageGroups[currentIndex];
    if (!currentGroup) return;

    if (cycleStep === 2 && currentGroup.fromSingle) {
      setCycleStep(1);
      setDisplayedImage(currentGroup.fromSingle);
    } else if (cycleStep === 1 && currentGroup.twoImg) {
      setCycleStep(0);
      setDisplayedImage(currentGroup.twoImg);
    } else if (cycleStep === 0 && currentGroup.toSingle) {
      // Cycle back to toSingle within the same group
      setCycleStep(2);
      setDisplayedImage(currentGroup.toSingle);
    } else {
      console.warn(
        `Image for cycleStep ${cycleStep - 1} is missing in group ${currentIndex}. Staying on current image.`
      );
      // Do not change cycleStep or displayedImage
    }
  };

  const resetCycle = () => {
    setCycleStep(0);
    // The displayedImage will be updated by the useEffect listening to currentIndex
  };

  return {
    currentIndex,
    cycleStep,
    displayedImage,
    handleNextCycle,
    handlePrevCycle,
    setCurrentIndex,
    resetCycle,
  };
};

export default useRockfallCycle;

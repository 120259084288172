// src/components/LandslideProjectDetail/hooks/usePopupManagement.js

import { useState, useCallback, useRef } from 'react';
import api from '../../../../api';

const usePopupManagement = (projectId, displacementType, authToken, calculateAverageTimeseries) => {
    const [popups, setPopups] = useState([]);
    const [groupColors, setGroupColors] = useState({});
    const colorIndex = useRef(0);
    const colorPalette = useRef([
        '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728',
        '#9467bd', '#8c564b', '#e377c2', '#7f7f7f',
        '#bcbd22', '#17becf'
    ]);

    const addSinglePopup = useCallback((point, timeseries) => {
        setPopups(prevPopups => {
            const exists = prevPopups.some(popup => popup.type === 'single' && popup.pointId === point.pointId);
            if (exists) return prevPopups;

            const newPopup = {
                id: Date.now() + Math.random(),
                type: 'single',
                pointId: point.pointId,
                x: point.x,
                y: point.y,
                timeseries: timeseries,
                loading: timeseries.length === 0,
                position: { x: 100, y: 100 }
            };
            return [...prevPopups, newPopup];
        });
    }, []);

    const addMultiPopup = useCallback((points, groupId = null) => {
        const sortedPointIds = points.map(p => p.pointId).sort();
        const computedGroupId = groupId || sortedPointIds.join('-');

        setPopups(prevPopups => {
            const exists = prevPopups.some(popup => 
                popup.type === 'multi' && 
                popup.groupId === computedGroupId
            );
            if (exists) return prevPopups;

            let assignedColor = groupColors[computedGroupId];
            if (!assignedColor) {
                assignedColor = colorPalette.current[colorIndex.current % colorPalette.current.length];
                setGroupColors(prevColors => ({ ...prevColors, [computedGroupId]: assignedColor }));
                colorIndex.current += 1;
            }

            const newPopup = {
                id: 'multi-' + computedGroupId + '-' + Date.now(),
                groupId: computedGroupId,
                type: 'multi',
                points: points.map(point => ({
                    pointId: point.pointId,
                    x: point.x,
                    y: point.y,
                    timeseries: [],
                })),
                averageTimeseries: [],
                loading: true,
                color: assignedColor,
                position: { x: 150, y: 150 }
            };
            return [...prevPopups, newPopup];
        });
    }, [groupColors]);

    const removePopup = useCallback((id) => {
        setPopups(prevPopups => prevPopups.filter(popup => popup.id !== id));
    }, []);

    const fetchTimeseriesData = useCallback(async (point) => {
        try {
            const res = await api.get(`/landslideData/${projectId}/${displacementType}/timeseries/${point.pointId}`, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
            setPopups(prevPopups => prevPopups.map(popup => {
                if (popup.type === 'single' && popup.pointId === point.pointId) {
                    return { ...popup, timeseries: res.data.timeseries, loading: false };
                }
                return popup;
            }));
        } catch {
            setPopups(prevPopups => prevPopups.map(popup => {
                if (popup.type === 'single' && popup.pointId === point.pointId) {
                    return { ...popup, loading: false };
                }
                return popup;
            }));
        }
    }, [projectId, displacementType, authToken]);

    const updateMultiPopup = useCallback((groupId, data) => {
        setPopups(prevPopups => prevPopups.map(popup => {
            if (popup.type === 'multi' && popup.groupId === groupId) {
                const averageTimeseries = calculateAverageTimeseries(Object.values(data));
                return {
                    ...popup,
                    averageTimeseries: averageTimeseries,
                    loading: false
                };
            }
            return popup;
        }));
    }, [calculateAverageTimeseries]);

    const fetchTimeseriesForMultiplePoints = useCallback(async (points, groupId) => {
        if (points.length === 0) {
            return;
        }

        const pointIds = points.map(p => p.pointId).join(',');

        try {
            const res = await api.get(`/landslideData/${projectId}/${displacementType}/timeseries`, {
                headers: { 'Authorization': `Bearer ${authToken}` },
                params: { pointIds }
            });
            const { data, pointsWithoutData } = res.data;

            updateMultiPopup(groupId, data);

            if (pointsWithoutData.length > 0) {
                // Handle points without data if necessary
            }
        } catch {
            // Handle error if necessary
        }
    }, [projectId, displacementType, authToken, updateMultiPopup]);

    return {
        popups,
        addSinglePopup,
        addMultiPopup,
        removePopup,
        fetchTimeseriesData,
        fetchTimeseriesForMultiplePoints,
        groupColors,              // Exposed groupColors
        colorPalette              // Exposed colorPalette
    };
};

export default usePopupManagement;

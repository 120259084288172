// src/utils/basemaps.js

export const basemapOptions = [
  {
    id: 'streets',
    name: 'Streets',
    type: 'vector', // Added type for consistency
    styleURL: 'mapbox://styles/mapbox/streets-v12',
  },
  {
    id: 'satellite',
    name: 'Satellite',
    type: 'vector',
    styleURL: 'mapbox://styles/mapbox/satellite-v9',
  },
  {
    id: 'satellite-streets',
    name: 'Satellite Streets',
    type: 'vector',
    styleURL: 'mapbox://styles/mapbox/satellite-streets-v12',
  },
  {
    id: 'outdoors',
    name: 'Outdoors',
    type: 'vector',
    styleURL: 'mapbox://styles/mapbox/outdoors-v12',
  },
  {
    id: 'light',
    name: 'Light',
    type: 'vector',
    styleURL: 'mapbox://styles/mapbox/light-v11',
  },
  {
    id: 'dark',
    name: 'Dark',
    type: 'vector',
    styleURL: 'mapbox://styles/mapbox/dark-v11',
  },
  {
    id: 'navigation-night',
    name: 'Navigation Night',
    type: 'vector',
    styleURL: 'mapbox://styles/mapbox/navigation-night-v1',
  },
  {
    id: 'navigation-day',
    name: 'Navigation Day',
    type: 'vector',
    styleURL: 'mapbox://styles/mapbox/navigation-day-v1',
  }
];
